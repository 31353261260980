import React, { useState, useEffect, useContext } from "react";
import { Variables } from "../../../../../1.resources/2.js/0.global/2.contracts/variables";
import 'react-loading-skeleton/dist/skeleton.css'
import { ItemsList } from "./partials/itemsList";


export const MarketplaceListings = ({ setFloorPrice }) => {

    async function floorCallback(items) {
        setFloorPrice(items.length > 0 ? items[0].cost : null)
    }
    return (
        <div>
            <ItemsList url="/database/marketplace/items/get"
                contractAddress={window.location.pathname.includes("domain") ? Variables().wnsErc721Addr : Variables().wnsMembershipAddr}
                floorCallback={floorCallback}
                defaultRowItems={6} />
        </div>
    )
}



