import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AccountSidebar = ({ content, setContent }) => {


    let items = [{ name: "My Domains", icon: 'fa-user', link: () => setContent("My Domains") },
    { name: "Manage", icon: 'fa-pen', link: () => setContent("Manage") },
    /*  { name: "Drafts", icon: 'fa-file', link: "" }, */
    // { name: "Wishlist", icon: 'fa-star', link: () => setContent("Wishlist") },
    /*  { name: "Referral Program", icon: 'fa-globe', link: () => setContent("Referral Program") }, */
    { name: "Docs", icon: 'fa-book', link: () => window.open("https://docs.wnsdomains.io/", "_blank") }]


    async function init() {
    }
    useEffect(() => {
        init();
    }, [])
    return (
        <div style={{ minHeight: window.innerHeight, marginTop: "15px" }} className="">
            <GetMappingSidebar paramsArray={items} content={content} />
        </div >
    );

}

export default AccountSidebar;


const GetMappingSidebar = ({ paramsArray, content }) => {

    const SidebarMapping = paramsArray.map((item, index) => {

        return (
            <div key={index}>
                <div onClick={() => item.link()} style={{ display: "flex", cursor: "pointer", padding: "15px", paddingLeft: "5%", borderRadius: "50px", marginBottom: "2%" }} className={content == item.name ? ("onlyvertcenter  bg-dark900") : "onlyvertcenter sidebardivs hover:bg-dark900"}>

                    <div style={{ width: "20%" }} className="vertcenter">
                        <FontAwesomeIcon icon={['fas', item.icon]} style={{ fontSize: "90%" }} className={`${content == item.name ? "text-white" : "text-dark500"}`} />
                    </div>
                    <div style={{ width: "85%" }}>
                        <p className={content == item.name ? "font-semibold text-white text-md" : "font-semibold text-dark500 text-md"} >{item.name}</p>
                    </div>
                </div>
            </div>
        )
    }
    );

    return SidebarMapping;
}