import React, { useState, useEffect, useContext } from "react";
import UserImg from "../../../../1.resources/3.files/images/user.png"
import { Variables } from "../../../../1.resources/2.js/0.global/2.contracts/variables";
import { ItemsList } from "../../../4.marketplace/partials/items/partials/partials/itemsList";
import { shortenaddress } from "../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import PrimaryName from "./partials/primaryName";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from "../../../../1.resources/1.css/colors";
import makeBlockie from "ethereum-blockies-base64";
import { zeroAddress } from "../../../../1.resources/2.js/0.global/0.smallfunctions/prepends";

const MyDomains = ({ currentAddress }) => {
    const [domain, setDomain] = useState("");
    const [tabSelected, setTabSelected] = useState("items");
    const [primaryVisible, setPrimaryVisible] = useState(false);

    async function init() {
        let result = await (await fetch("https://resolver.wnsdomains.io/resolve/address=" + currentAddress)).json();
        if (result.value != "null") {
            setDomain(result.value);
        }
    }
    useEffect(() => {
        init();
    }, [currentAddress])

    useEffect(() => {

    }, [tabSelected])
    return (
        <div style={{ padding: "0%", paddingTop: "0%" }}>
            {/*  <div id="marketplaceHeader" style={{
                marginLeft: "0%", marginRight: "0%", padding: window.innerWidth < 650 ? "10%" : "4%", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px",
                backgroundImage: `url(${isDark ? MarketplaceBannerDark : MarketplaceBanner})`, borderRadius: "12.5px"
            }} className={isDark ? "shadowDark" : "shadow"}>
            </div> */}




            <div className="px-5 md:px-12 pt-10">
                <p className="text-lg font-bold text-white" style={{}}>/ My domains</p>

                <div className={`py-4  ${window.innerWidth < 800 ? "" : "flex items-center justify-between"}`} style={{ marginTop: "2%", marginBottom: "2%" }}>
                    <div className="flex items-center gap-x-4">
                        {/* <div className="bg-gray-400 w-14 h-14 flex justify-center items-center rounded-2xl">
                            <FontAwesomeIcon icon="user" className="text-white text-xl" />
                        </div> */}
                        <img src={makeBlockie(currentAddress ? currentAddress : zeroAddress)} className="w-14 h-14 rounded-2xl" />
                        <div>
                            <p className="text-2xl font-bold text-white" style={{}}>{domain === "" ? "Account" : domain}</p>
                            <p className="text-dark500 text-lg">{shortenaddress(currentAddress)}</p>
                        </div>
                    </div>

                    <div className="py-5 md:py-0">
                        <div style={{ display: primaryVisible ? "none" : "block" }} onClick={() => setPrimaryVisible(true)} >
                            <button className="text-md mt-4 md:mt-0 text-blue-500 font-semibold">{domain === "" ? "Set Primary Name" : "Change Primary Name"}</button>
                            <FontAwesomeIcon icon="chevron-right" className="text-blue-500 text-sm ml-2" />
                        </div>
                        <div style={{ display: primaryVisible ? "block" : "none" }}>
                            <PrimaryName currentAddress={currentAddress} setPrimaryVisible={setPrimaryVisible} />
                        </div>
                    </div>
                </div>

            </div>
            <div className="px-5 md:px-12 flex items-center gap-x-10 border-b-2 border-b-dark800 pt-2.5">
                <button onClick={() => setTabSelected("items")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected === "items" ? ("border-b-2 border-b-white") : ("text-dark500")}`}>
                    <FontAwesomeIcon icon={['fas', 'fa-box']} style={{ color: tabSelected === "items" ? "#fff" : colors.gray500 }} />
                    <p className={`text-md ${tabSelected === "items" ? "text-white" : "text-dark500"}`}>Items</p>
                </button>
                <button onClick={() => setTabSelected("membership")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected === "membership" ? ("border-b-2 border-b-white") : ("text-dark500")}`}>
                    <FontAwesomeIcon icon={['fas', 'fa-chart-line']} style={{ color: tabSelected === "membership" ? "#fff" : colors.gray500 }} />
                    <p className={`text-md ${tabSelected === "membership" ? "text-white" : "text-dark500"}`}>Membership</p>
                </button>
            </div>




            <div style={{ marginTop: window.innerWidth < 800 ? "2.5%" : "0%" }} className="bg-dark900 px-5 md:px-12 border-t border-dark800 min-h-screen rounded-b-3xl">
                <ItemsList url="/database/account/tokens/get" currentAddress={currentAddress} contractAddress={tabSelected == "items" ? Variables().wnsErc721Addr : Variables().wnsMembershipAddr} defaultSorting="desc" defaultRowItems={6} type="short2" />
            </div>
        </div >
    );

}

export default MyDomains;

