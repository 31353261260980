import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetDomain } from "./activity";
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";
import WnsLogo from "../../0.global/logo/wnsLogo";
import { colors } from "../../../1.resources/1.css/colors";
import makeBlockie from "ethereum-blockies-base64";
import { zeroAddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import { getWnsDomain } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
const punycode = require('punycode/');

const Titles = ({ invalid, domain, registered, tokenId, owner, tokenLoading }) => {
    const [domainValue, setDomainValue] = useState("");
    const [isDomain, setIsDomain] = useState(false);

    async function parseDomain(address) {
        let result = await getWnsDomain(address);
        if (result && result != "null") {
            setIsDomain(true);
            setDomainValue(result);
        }
    }
    useEffect(() => {
        init();
        console.log(invalid);
        if (owner != "") {
            setDomainValue(shortenaddress(owner));
            parseDomain(owner)
        }
    }, [owner, invalid]);

    async function init() {
        let hex = "😀".codePointAt(0).toString(16)
        let emo = String.fromCodePoint("0x" + hex);

        console.log(hex, emo);
    }
    return (
        <div className="py-5 pb-10 md:pt-0 border-b-2 border-dark800 w-full">
            <a href={window.location.pathname.includes("name") ? "/marketplace/domains" : "/marketplace/membership"} className="text-lg text-blue-500">W3 <span className="">
                {invalid ? "" : window.location.pathname.includes("name") ? "Name Service" : "Membership"}
            </span> </a>
            <p className="font-bold text-4xl md:text-6xl py-3 pb-6 text-white">
                {invalid ? "Invalid Token" : window.location.pathname.includes("name") ? `${punycode.toUnicode(domain)}.web3` : `Early Member #${tokenId}`}
            </p>
            <div style={{}} className="onlyvertcenter">
                {invalid ? (
                    <p className="text-dark500 text-lg" >This item is invalid.</p>
                ) : (
                    registered ? (
                        isDomain ? (
                            <div className="flex items-center gap-x-2">
                                <p className="text-dark500 text-lg" >Owned by: </p>
                                <div className="flex gap-1 items-center bg-dark900 rounded-full px-4 py-2">
                                    <img src={makeBlockie(owner ? owner : zeroAddress)} className="w-5 h-5 rounded-full" />
                                    <a className="text-main2 flex items-center" target="_blank"
                                        href={"https://opensea.io/" + owner}>{domainValue}</a>
                                </div>
                            </div>

                        ) : (
                            <div className="flex items-center gap-x-2">
                                <p className="text-dark500 text-lg" >Owned by: </p>
                                <div className="flex gap-1 items-center bg-dark900 rounded-full px-4 py-2">
                                    <img src={makeBlockie(owner ? owner : zeroAddress)} className="w-5 h-5 rounded-full" />
                                    <a className="text-main2 flex items-center" target="_blank"
                                        href={"https://opensea.io/" + owner}>
                                        <p>{domainValue}</p>
                                    </a>
                                </div>
                            </div>
                        )

                    ) : (
                        !tokenLoading ? (
                            <div className="flex items-center gap-x-2">
                                <div className="w-5 h-5 flex justify-center items-center bg-main rounded-full">
                                    <FontAwesomeIcon icon="check" className="text-white" size="xs" />
                                </div>
                                <p className="text-main text-md" >Available to register</p>

                            </div>
                        ) : (null)
                    )
                )}
            </div>
        </div >
    );

}

export default Titles;
