import React, { useState, useEffect, useContext } from "react";
import MarketplaceLogoImg from "../../../../1.resources/3.files/logo/logo_color.webp";
import MembershipLogoImg from "../../../../1.resources/3.files/logo/membership2.png";
import MarketplaceBanner from "../../../../1.resources/3.files/images/marketbanner3.png";
import MembershipBanner from "../../../../1.resources/3.files/images/membershipBanner.png";
import CloudContracts from "../../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MarketplaceUpper = ({ floorPrice }) => {
    const [market, setMarket] = useState("");
    const [totalItems, setTotalItems] = useState("");
    const [owners, setOwners] = useState("");
    const [volume, setVolume] = useState("");

    async function init() {
        let url;
        if (window.location.pathname.includes("domain")) {
            url = "https://api.opensea.io/api/v1/collection/wnsdomains/stats"
        } else {
            url = "https://api.opensea.io/api/v1/collection/wns-membership/stats"
        }
        let stats = (await (await fetch(url, {
            headers: {
                Accept: "application/json",
                "X-Api-Key": "a4fe8c6e08874716ab2db84cab28fb44"
            }
        })).json()).stats;
        console.log(stats);
        setTotalItems(stats.total_supply.toLocaleString('en-US'));
        setOwners(stats.num_owners.toLocaleString('en-US'));
        setVolume(stats.total_volume.toFixed(2));
    }

    useEffect(() => {
        if (window.location.pathname.includes("domain")) {
            setMarket("domain");
            document.getElementById("title").innerHTML = "Domains Marketplace - W3 Name Service"
        } else {
            setMarket("marketplace");
            document.getElementById("title").innerHTML = "Membership Marketplace - W3 Name Service"
        }
        init();
    }, []);

    let css = {
        req: "font-semibold text-xl text-white",
        res: "text-md text-dark500 mt-1"
    }

    return (
        <div style={{}} className="w-full">
            {/* <div id="" className="border-2 border-b-gray-200 rounded-2xl pt-4" style={{
                marginLeft: "0%", marginRight: "0%", padding: window.innerWidth < 800 ? "10%" : "7%",
                backgroundImage: `url(${market == "domain" ? (MarketplaceBanner) : MembershipBanner})`
            }}>
            </div> */}

            <div className="px-0 md:px-0 pt-4">
                <img src={market == "domain" ? MarketplaceLogoImg : MembershipLogoImg} className="bg-dark900 w-32 h-32 rounded-2xl p-1"></img>
                <p className="font-semibold text-4xl mt-8 text-white">
                    {market == "domain" ? "WNS: W3 Name Service" : "WNS: Membership Pass"}
                </p>
                <p className="text-dark500 mt-4 w-full md:w-[600px] text-md">
                    {
                        market == "domain" ? "Web3 Name Service (WNS) domains are your username for the Web3 world. WNS names provide a way to map names/numbers to your Ethereum wallet address and act as the representation of your account. "
                            : "WNS 'Early Member' membership passes were rewarded to the early supporters of the WNS ecosystem. The pass provides additional perks to the owners including discounts on domains up to 50%"
                    }</p>



                <div className="mt-10 mb-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-8 w-full md:w-[600px] rounded-2xl pl-0 text-start">
                        <div style={{ padding: "0%" }}>
                            <p className={css.req} >{totalItems ? totalItems : "-"}</p>
                            <p className={css.res}  >items</p>
                        </div>
                        <div style={{ padding: "0%" }}>
                            <p className={css.req} >{owners ? owners : "-"}</p>
                            <p className={css.res}>owners</p>
                        </div>
                        <div style={{ padding: "0%" }}>
                            <div style={{ display: "flex" }} className="flex items-center">
                                <p className={css.req} >{floorPrice != "" ? (floorPrice / 1e18).toFixed(2) : "-"} ETH</p>
                            </div>
                            <p className={css.res}>floor price</p>
                        </div>
                        <div style={{ padding: "0%" }}>
                            <div style={{ display: "flex" }} className="flex items-center">
                                <p className={css.req} >{volume ? volume : "-"} ETH</p>
                            </div>
                            <p className={css.res}>volume</p>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );

}

export default MarketplaceUpper;
