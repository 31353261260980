import React, { useState, useEffect, useContext } from "react";
import { callW3Api } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { calculateZeroes, usdToEth } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { getCloudProvider } from "../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { ethers } from "ethers";
import { useSigner } from 'wagmi'
import EmptyImg from "../../../1.resources/3.files/images/empty.png";

const CartSidebar = ({ cart, currentAddress, cartLoading }) => {
    const { data: signer, isError, isLoading } = useSigner()

    const [coupon, setCoupon] = useState([]);
    const [couponValidity, setCouponValidity] = useState(false);
    const [cartInfoVisible, setCartInfoVisible] = useState(false);
    const [cost, setCost] = useState(0);
    const [membershipCost, setMembershipCost] = useState(0);
    const [total, setTotal] = useState(0);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinnerp, setSpinnerp] = useState("");
    const [linkVisible, setLinkVisbible] = useState(false);
    const [linkHref, setLinkHref] = useState("");
    const [errorVisible, setErrorVisbible] = useState(false);

    async function init() {
        if (cart.length != 0) {
            let [costs, membershipCosts] = await Promise.all([Promise.all(cart.map(async (item) => {
                return usdToEth(item.cost);
            })), Promise.all(cart.map(async (item) => {
                return usdToEth(item.membershipCost);
            }))]);
            console.log(costs);
            console.log(membershipCosts);

            let total = 0;
            let membershipTotal = 0;
            for (let i = 0; i < cart.length; i++) {
                total = total + parseFloat(costs[i]);
                membershipTotal = membershipTotal + parseFloat(membershipCosts[i]);
            }
            console.log(total);
            setCost(total * 1e18);
            setMembershipCost(membershipTotal * 1e18);
            if (membershipTotal == 0) {
                setTotal(total * 1e18);
            } else {
                setTotal(membershipTotal * 1e18);
            }
        }

    }
    useEffect(() => {
        init()
    }, [cart])

    function couponInput(value) {
        setCouponValidity(false);
        if (!value.includes(".") && isAlphaNumeric(value) && value.length < 15) {
            setCoupon(value.toLowerCase());
        }
    }

    async function addCoupon() {
        let find = await callW3Api("/database/account/coupons/find", { couponName: coupon });
        console.log(find);
        if (find != null) {
            setCartInfoVisible(true);
            let couponShare = 20 / 100 * total;
            setTotal(total - couponShare);
            setCouponValidity(true);
        }
    }

    async function register() {
        try {
            setSpinnerp("Please wait...")
            setSpinnerVisible(true);

            let paramsPromise = []
            for (let i = 0; i < cart.length; i++) {
                paramsPromise.push(callW3Api("/database/tokens/register/getParams", { address: currentAddress, name: cart[i].name.substring(0, cart[i].name.indexOf(".")), couponName: couponValidity == true ? coupon : "" }));
            }
            let params = await Promise.all(paramsPromise);

            let tuples = []
            let signArray = []
            let cost = 0;
            for (let i = 0; i < cart.length; i++) {
                let tuple = [params[i].msg.name, params[i].msg.extension, params[i].msg.registrant, params[i].msg.cost, params[i].msg.expiration, params[i].msg.splitAddresses, params[i].msg.splitAmounts];
                let sign = params[i].signature;
                tuples.push(tuple);
                signArray.push(sign);
                cost = cost + parseFloat(params[i].msg.cost);
                console.log(params[i].msg.cost);
                console.log(cost);
            }
            console.log(tuples);
            console.log(signArray);
            console.log(cost)
            console.log((parseInt(cost)).toLocaleString('fullwide', { useGrouping: false }))
            const wnsRegistrarContract = new ethers.Contract(Variables().wnsRegistrarAddr, Variables().wnsRegistrarAbi, signer);
            setSpinnerp("Confirm the transaction in your wallet...")
            const tx = await wnsRegistrarContract.wnsRegister(tuples, signArray, { value: (parseInt(cost)).toLocaleString('fullwide', { useGrouping: false }) });
            setSpinnerVisible(false);
            setLinkVisbible(true);
            setLinkHref("https://etherscan.io/tx/" + tx.hash);
        } catch (e) {
            setSpinnerVisible(false);
            setErrorVisbible(true);
        }
    }
    return (
        <div className="bg-dark900 my-0 lg:my-4 lg:rounded-3xl flex px-5 md:px-10 py-8 lg:border-2 border-dark800 lg:min-h-screen" style={{}}>
            {!cartLoading ? (
                cart.length != 0 ? (
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }} className="onlyvertcenter">
                            <div style={{ display: "flex" }} className="items-center">

                                <img src={process.env.REACT_APP_API_URL + "/metadata/generateimage=" + cart[0].name + "?200"} className="w-16 h-16 rounded-xl" />



                                <div style={{ marginLeft: "10%" }}>
                                    <p className="font-semibold whitespace-nowrap text-xl text-white">W3 Name Service</p>
                                    <p className="text-dark500 text-lg" >Items: {cart.length}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "none", justifyContent: "space-between", marginTop: "8%", width: "100%" }} className="onlyvertcenter">
                            <div className="w-full rounded-xl p-2 bg-white border-2 border-gray-200">
                                <input value={coupon} onChange={(e) => couponInput(e.target.value)} type="text" placeholder="Have a coupon code?" className="font-semibold" style={{ color: "#000", paddingLeft: "3%", fontSize: "100%", width: "100%", background: "#fff", outline: "0", borderRadius: "12.5px", border: "transparent" }} ></input>
                            </div>
                            <button onClick={() => addCoupon()} className="mainButtonHollow" style={{ marginLeft: "5%" }}>Apply</button>
                        </div>
                        <div style={{ display: cartInfoVisible ? "flex" : "none", marginTop: "2%", }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-check']} style={{ color: "#000", }} />
                            <p style={{ fontWeight: "semibold", color: "#000", marginLeft: "1%", whiteSpace: "nowrap" }}>Coupons has been successfully applied</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8%", paddingTop: "6%" }} className="onlyvertcenter  border-t-2 border-t-dark800">
                            <p className="whitespace-nowrap text-dark500 text-md font-semibold">Subtotal</p>
                            <p className="whitespace-nowrap font-semibold text-md font-semibold text-white">{(cost / 1e18).toFixed(3)} ETH</p>
                        </div>
                        <div style={{ display: membershipCost == 0 ? "none" : "flex", justifyContent: "space-between", marginTop: "4%" }} className="onlyvertcenter">
                            <p className="whitespace-nowrap text-dark500 text-md font-semibold">Membership Offer</p>
                            <p className="whitespace-nowrap font-semibold text-md font-semibold text-blue-500">- {((cost - membershipCost) / 1e18).toFixed(3)} ETH</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6%", paddingTop: "6%" }} className="onlyvertcenter border-t-2 border-t-dark800">
                            <p className="whitespace-nowrap text-dark500 text-md font-semibold">Gas fees</p>
                            <p className="whitespace-nowrap text-md font-semibold  text-dark500">To be calculated</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10%" }} className="onlyvertcenter">
                            <p className="whitespace-nowrap font-semibold text-md text-dark500">Total</p>
                            <p className="whitespace-nowrap font-semibold text-3xl  text-white">{(total / 1e18).toFixed(3)} ETH</p>
                        </div>

                        <div style={{ marginTop: "10%", marginBottom: "-2%" }}>
                            <div style={{ display: spinnerVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="onlyvertcenter gap-x-1">
                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-main" spin />
                                <p style={{ fontWeight: "semibold", marginLeft: "1%", whiteSpace: "nowrap" }} className="text-main">{spinnerp}</p>
                            </div>
                            <div style={{ display: errorVisible ? "flex" : "none", marginBottom: "1%", marginTop: "2%" }} className="onlyvertcenter gap-x-1">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", }} />
                                <p style={{ fontWeight: "semibold", color: "#D55554", marginLeft: "1%", whiteSpace: "nowrap" }}>Something went wrong</p>
                            </div>
                            <div style={{ display: linkVisible ? "flex" : "none", marginBottom: "1%", marginTop: "2%" }} className="onlyvertcenter gap-x-1">
                                <a href={linkHref} target="_blank" style={{ fontWeight: "semibold", whiteSpace: "nowrap" }} className="text-main">View transaction on Etherscan</a>
                                <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} className="text-main" style={{ marginLeft: "1%" }} />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6%" }} className="onlyvertcenter">
                            <p style={{ fontSize: "100%", color: "#000", whiteSpace: "nowrap" }}></p>
                            <button onClick={() => register()} className="bg-main text-white rounded-full px-4 py-2">Register</button>
                        </div>
                    </div>
                ) : (
                    <div className="text-center">
                        <img src={EmptyImg} className="w-full" />
                        <p className="text-xl mt-8 font-semibold">Your cart is empty</p>
                        <p className="text-dark500">Add some items to your cart</p>
                    </div>
                )
            ) : (
                <div className="flex justify-center items-center h-screen w-full pb-20">
                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#fff", fontSize: "150%" }} spin />
                </div>
            )}

        </div >
    );

}

export default CartSidebar;


function isAlphaNumeric(str) {
    var code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false;
        }
    }
    return true;
};