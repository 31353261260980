import React, { useState, useEffect, useContext } from "react";
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { callW3Api } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { ItemsList } from "../../4.marketplace/partials/items/partials/partials/itemsList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usdToEth } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";


const CartMain = ({ currentAddress, setCart, cart, setCartLoading }) => {
    const [cartSelected, setCartSelected] = useState([]);
    const [refresh, setRefresh] = useState(false);

    async function init() {

        console.log(currentAddress)
    }
    useEffect(() => {
        init();
    }, [currentAddress])

    function getSelected(value) {
        console.log(value);
        setCartSelected(value);
    }

    function itemsCallback(value) {
        console.log(value);
        setCart(value);
        setCartLoading(false);
    }

    async function removeFromCart() {
        console.log(cartSelected);
        let names = []
        for (let i = 0; i < cartSelected.length; i++) {
            names.push(cartSelected[i]);
        }
        console.log(names);
        await callW3Api("/database/marketplace/cart/update/remove", { address: currentAddress, names: names });
        window.location.reload();
    }

    useEffect(() => {
        console.log(cartSelected);
    }, [cartSelected])
    return (
        <div className="px-0 pt-5 md:pt-10 h-full" style={{}}>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
                <div>
                    <p className="font-semibold text-2xl md:text-3xl text-center md:text-start text-white">My Cart</p>
                    <p className="text-dark500 mt-2 md:text-lg text-center md:text-start">Total items: {cart.length}</p>
                </div>
                <div onClick={() => removeFromCart()} style={{ display: cartSelected.length != 0 ? "block" : "none", justifyContent: "flex-end" }} className="cursor-pointer w-fit mt-4 md:mt-0" key={refresh}>
                    <p className="bg-main text-white px-4 py-2 rounded-full text-sm">Remove from cart</p>
                </div>
            </div>
            {/* <div>
                {currentAddress != "" ? (
                    <ItemsList url="/database/marketplace/cart/get/all"
                        currentAddress={currentAddress}
                        contractAddress={Variables().wnsErc721Addr}
                        defaultRowItems={4}
                        tabsVisibility={false}
                        select={true}
                        selectCallback={getSelected}
                        itemsCallback={itemsCallback}
                        type="short" />
                ) : (<div />)}
            </div> */}
            <div className="my-6 bg-dark900 px-5 md:px-10 py-6 rounded-2xl border-2 border-dark800">
                {cart.length != 0 ? (
                    <CartMapping cart={cart} setCart={setCart} currentAddress={currentAddress} cartSelected={cartSelected} setCartSelected={setCartSelected} refresh={refresh} setRefresh={setRefresh} />
                ) : (
                    null
                )}
            </div>
        </div >
    );

}

export default CartMain;

const CartMapping = ({ cart, setCart, cartSelected, setCartSelected, currentAddress, refresh, setRefresh }) => {
    console.log(cart);

    function toggleCart(name) {

        let newCartSelected = cartSelected;
        console.log(newCartSelected);
        if (cartSelected.includes(name)) {
            newCartSelected.splice(newCartSelected.indexOf(name), 1);
        } else {
            newCartSelected.push(name);
        }
        console.log(newCartSelected);
        setCartSelected(newCartSelected);
        setRefresh(!refresh);
    }

    return (
        cart.map((item, index) => (
            <div key={index} className="flex justify-between items-center py-4">

                <div className="flex items-center">
                    <div className="" onClick={() => toggleCart(item.name)} key={refresh} >
                        <FontAwesomeIcon icon={[cartSelected.includes(item.name) ? "fas" : "far", "fa-square-check"]} className="text-gray-400 text-lg" />
                    </div>
                    <div className="hidden md:block w-14 h-14 bg-gray-300 rounded-lg ml-6">
                        <img src={process.env.REACT_APP_API_URL + "/metadata/generateimage=" + item.name + "?200"} className="w-14 h-14 rounded-lg" />
                    </div>
                    <div className="ml-4 md:ml-3">
                        <p className="font-semibold text-lg text-white">{item.name}</p>
                        <p className="text-dark500 text-md">{item.name.substring(0, item.name.indexOf(".")).length} characters</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <Price cost={item.cost} />

                </div>
            </div>
        ))
    )
}

const Price = ({ cost }) => {
    const [eth, setEth] = useState(0);

    async function init() {
        setEth(await usdToEth(cost));
    }
    useEffect(() => {
        init();
    }, [cost])
    return (
        <div className="">
            <p className="font-semibold text-lg text-end text-white">{eth} ETH</p>
            {/* <p className="text-dark500 text-md ml-2 text-end">(${cost})</p> */}
        </div>
    )
}