import { useConnectModal } from "@rainbow-me/rainbowkit";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useAccount } from "wagmi";
import { Variables } from "../../1.resources/2.js/0.global/2.contracts/variables";
import { ConnectWallet } from "../0.global/wallet/connectWallet";
import AccountMain from "./partials/main";
import AccountSidebar from "./partials/sidebar";

const Account = ({ connectModal, setConnectModal }) => {
    const { openConnectModal } = useConnectModal();
    let { address } = useAccount();
    const [currentAddress, setCurrentAddress] = useState("");
    const [content, setContent] = useState("");

    async function init() {
        setCurrentAddress(address);
        if (address == "" || address == null) {
            openConnectModal();
        }
        let url = window.location.pathname.split("/");
        if (url[2] == "mydomains") {
            setContent("My Domains");
            document.getElementById("title").innerHTML = "My Domains - W3 Name Service"
        } else if (url[2] == "manage") {
            setContent("Manage");
            document.getElementById("title").innerHTML = "Manage - W3 Name Service"
        } else if (url[2] == "wishlist") {
            setContent("Wishlist");
            document.getElementById("title").innerHTML = "Wishlist - W3 Name Service"
        } else if (url[2] == "referral") {
            setContent("Referral Program");
        } else {
            setContent("My Domains");
            document.getElementById("title").innerHTML = "W3 Name Service"
        }

    }
    useEffect(() => {
        init();
    }, [])

    return (
        <div className="w-full flex-1 flex justify-center" id="mainpart">
            <div className="w-full 2xl:w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                {currentAddress != "" && currentAddress != null ? (
                    <div style={{ display: "flex" }} className="w-full gap-x-10">
                        <div className="hidden lg:block w-[300px] flex flex-none">
                            <AccountSidebar currentAddress={currentAddress} content={content} setContent={setContent} />
                        </div>
                        <div className="w-full">
                            <AccountMain currentAddress={currentAddress} content={content} setContent={setContent} />
                        </div>
                    </div>
                ) : (
                    <div style={{ minHeight: window.innerHeight * 0.5 }}>
                    </div>

                )}
            </div >
        </div>
    );

}

export default Account;
