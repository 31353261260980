import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount, useDisconnect } from 'wagmi'
import { ConnectWallet } from "../wallet/connectWallet";

const MobileSidebar = ({ walletConnected, setMobileSidebar, setConnectModal }) => {
    let { address } = useAccount();
    const { disconnect } = useDisconnect()

    const [marketVisible, setMarketVisible] = useState(false)
    const [categoryVisible, setCategoryVisible] = useState(false)
    const [accountVisible, setAccountVisible] = useState(false)

    return (
        <div style={{ zIndex: "1000023409324234234", minHeight: window.innerHeight, background: "#000", padding: "5%" }} className="font-semibold">

            <div onClick={() => { setMobileSidebar(false) }} style={{ display: address != null ? "none" : "flex", paddingTop: "0%", paddingBottom: "5%", borderRadius: "12.5px" }} className={" onlyvertcenter"}>
                <ConnectWallet />
            </div>
            <div style={{ color: "#000" }}>
                <div onClick={() => window.location = "/"} style={{ padding: "2%", paddingLeft: "5%", marginBottom: "2%" }}>
                    <p style={{ color: "#fff" }}>Home</p>
                </div>
                <div style={{ padding: "2%", paddingLeft: "5%", marginBottom: "2%" }}>
                    <div onClick={() => setCategoryVisible(!categoryVisible)} style={{ justifyContent: "space-between" }} className="onlyvertcenter">
                        <p style={{ color: "#fff" }}>Categories</p>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#fff", fontSize: "100%" }} id="arrowicon" />
                        </div>
                    </div>
                    <div style={{ display: categoryVisible ? "block" : "none", padding: "2%" }}>
                        <div>
                            <p style={{ padding: "2.5%", paddingLeft: "5%", fontSize: "80%", color: "#fff" }}>Explore Categories</p>
                            <a href="/category/premium" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-star"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "7.5%", whiteSpace: "nowrap", color: "#fff" }}>Premium Domains</p>
                                </div>
                            </a>
                            <a href="/category/dictionary" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-book"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Dictionary</p>
                                </div>
                            </a>
                            <a href="/category/numbers" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-stopwatch-20"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Numbers</p>
                                </div>
                            </a>
                            <a href="/category/names" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-users"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Names</p>
                                </div>
                            </a>
                            <a href="/category/countries" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-globe"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Countries</p>
                                </div>
                            </a>

                            <a href="/category/cities" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-city"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Cities</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "2%", paddingLeft: "5%", marginBottom: "2%" }}>
                    <div onClick={() => setMarketVisible(!marketVisible)} style={{ justifyContent: "space-between" }} className="onlyvertcenter">
                        <p style={{ color: "#fff" }}>Marketplace</p>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#fff", fontSize: "100%" }} id="arrowicon" />
                        </div>
                    </div>
                    <div style={{ display: marketVisible ? "block" : "none", padding: "2%" }}>
                        <div>
                            <p style={{ padding: "2.5%", paddingLeft: "5%", fontSize: "80%", color: "#fff" }}>Explore Marketplace</p>
                            <a href="/marketplace/domains" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-star"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "7.5%", whiteSpace: "nowrap", color: "#fff" }}>Domains</p>
                                </div>
                            </a>
                            <a href="/marketplace/membership" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-book"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Membership</p>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
                <div style={{ padding: "2%", paddingLeft: "5%", marginBottom: "2%" }}>
                    <div onClick={() => setAccountVisible(!accountVisible)} style={{ justifyContent: "space-between" }} className="onlyvertcenter">
                        <p style={{ color: "#fff" }}>Account</p>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#fff", fontSize: "100%" }} id="arrowicon" />
                        </div>
                    </div>
                    <div style={{ display: accountVisible ? "block" : "none", padding: "2%" }}>
                        <div>
                            <p style={{ padding: "2.5%", paddingLeft: "5%", fontSize: "80%", color: "#fff" }}>Manage Account</p>
                            <a href="/account/mydomains" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-star"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "7.5%", whiteSpace: "nowrap", color: "#fff" }}>My domains</p>
                                </div>
                            </a>
                            <a href="/account/manage" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-book"]} style={{ fontSize: "90%", color: "#fff" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#fff" }}>Manage</p>
                                </div>
                            </a>
                            {/* <a href="/account/wishlist" style={{ padding: "2.5%", paddingLeft: "5%", display: "flex", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div className="onlyvertcenter">
                                    <div style={{ width: "30px" }}>
                                        <FontAwesomeIcon icon={['fas', "fa-book"]} style={{ fontSize: "90%", color: "#000" }} id="arrowicon" />
                                    </div>
                                    <p style={{ marginLeft: "15%", whiteSpace: "nowrap", color: "#000" }}>Wishlist</p>
                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>
                <div onClick={() => window.location = "/docs"} style={{ padding: "2%", paddingLeft: "5%", marginBottom: "2%" }}>
                    <p style={{ color: "#fff" }}>Docs</p>
                </div>
                <a onClick={() => disconnect()} style={{ padding: "2.5%", paddingLeft: "5%", display: address != null ? "flex" : "none", cursor: "pointer", justifyContent: "space-between" }} className="onlyvertcenter">
                    <div className="onlyvertcenter">
                        <p style={{ marginLeft: "0%", whiteSpace: "nowrap", color: "#fff" }}>Logout</p>
                    </div>
                </a>
            </div>
        </div >
    )

}

export default MobileSidebar;
