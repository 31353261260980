import React from 'react';
import Logo from "../../../1.resources/3.files/logo/logo_color.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {

    let css = {
        links: "text-gray-500 text-md py-0.5 hover:text-gray-400",
        heading: "text-gray-400 text-md font-semibold mb-1",
    }
    return (
        <div className='bg-dark900'>
            {/* <div className='flex items-center justify-center'>
                <div id="mainhead" className='lg:max-w-[1280px] w-full pt-20' >
                    <div className='flex justify-left items-left text-left md:text-left'>
                        <div>
                            <div className='flex justify-start md:justify-start'>
                                <img src={Logo} alt="logo" className="w-14 mb-8" />
                            </div>
                            <p className='text-white text-4xl font-semibold'>
                                W3 Name Service is part of the W3 ecosystem</p>

                            <div className='mt-4'>
                                <p className='text-gray-500 text-2xl'>
                                    W3 is helping build the third generation of Web.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='pt-14 pb-5 px-5 md:px-10 lg:px-0' >

                <div className='flex items-center justify-center w-full' >
                    <div className='lg:max-w-[1280px] w-full' >
                        <div className='block md:block'>
                            <div className='w-full'>
                                <div>
                                    <div style={{}} className="grid grid-cols-2 md:grid-cols-4 gap-5">
                                        <div style={{}}>
                                            <p className={css.heading}>Categories</p>
                                            <a className="graylink" href="/category/premium">
                                                <p className={css.links}>Premium</p>
                                            </a>
                                            <a className="graylink" href="/category/dictionary">
                                                <p className={css.links}>Dictionary</p>
                                            </a>
                                            <a className="graylink" href="/category/numbers">
                                                <p className={css.links}>Numbers</p>
                                            </a>
                                            <a className="graylink" href="/category/names">
                                                <p className={css.links}>Names</p>
                                            </a>
                                            <a className="graylink" href="/category/countries">
                                                <p className={css.links}>Countries</p>
                                            </a>
                                            <a className="graylink" href="/category/cities">
                                                <p className={css.links}>Cities</p>
                                            </a>
                                        </div>
                                        <div style={{}}>
                                            <p className={css.heading}>Marketplace</p>
                                            <a className="graylink" href="/marketplace/domains">
                                                <p className={css.links}>Domains</p>
                                            </a>
                                            <a className="graylink" href="/marketplace/membership">
                                                <p className={css.links}>Membership</p>
                                            </a>
                                        </div>
                                        <div id="links" style={{}}>
                                            <p className={css.heading}>Account</p>
                                            <a className="graylink" href="/account/mydomains">
                                                <p className={css.links}>My Domains</p>
                                            </a>
                                            <a className="graylink" href="/account/manage">
                                                <p className={css.links}>Manage</p>
                                            </a>
                                            <a className="graylink" href="/account/wishlist">
                                                <p className={css.links}>Wishlist</p>
                                            </a>
                                        </div>
                                        <div className='w-full mt-0'>
                                            <div>
                                                <div style={{}}>
                                                    <p className='text-gray-400 font-semibold text-md'>Join the community</p>
                                                    <div className="flex mt-4">
                                                        <a className='flex justify-center items-center bg-blue-500 cursor pointer flex justify-center items-center w-12 h-12 rounded-full' href="https://twitter.com/wnsdomains" target="_blank">
                                                            <FontAwesomeIcon icon={["fab", "fa-twitter"]} className="bg-blue-500 text-white" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div style={{}}>
                                                    <p className='text-gray-400 font-semibold text-md mt-8'>Email</p>
                                                    <div className="flex mt-1">
                                                        <p className='text-gray-500 text-md'>contact@w3.one</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br />

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Footer;
