

import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WnsLogo = ({ color }) => {

    return (
        <div className="w-50 h-50" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 500 500" width={"14px"} height={"14px"} preserveAspectRatio="xMidYMid meet" style={{ shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd" }}>
                <g><path style={{ opacity: "1" }} fill={color} d="M 239.5,-0.5 C 246.167,-0.5 252.833,-0.5 259.5,-0.5C 360.39,7.67633 433.224,57.0097 478,147.5C 489.423,174.197 496.59,201.863 499.5,230.5C 499.5,243.5 499.5,256.5 499.5,269.5C 488.699,362.65 442.032,431.15 359.5,475C 332.347,487.701 304.014,495.867 274.5,499.5C 257.833,499.5 241.167,499.5 224.5,499.5C 130.663,486.346 62.8295,437.346 21,352.5C 9.41619,325.495 2.24953,297.495 -0.5,268.5C -0.5,256.167 -0.5,243.833 -0.5,231.5C 7.68045,150.609 45.0138,87.1093 111.5,41C 150.604,16.0206 193.27,2.18722 239.5,-0.5 Z M 172.5,153.5 C 195.471,154.134 206.804,165.801 206.5,188.5C 188.653,238.199 170.487,287.866 152,337.5C 140.521,350.365 127.687,351.865 113.5,342C 104.198,334.345 100.698,324.512 103,312.5C 120.771,265.192 137.771,217.526 154,169.5C 157.928,161.47 164.095,156.137 172.5,153.5 Z M 269.5,153.5 C 292.471,154.134 303.804,165.801 303.5,188.5C 285.653,238.199 267.487,287.866 249,337.5C 237.521,350.365 224.687,351.865 210.5,342C 201.198,334.345 197.698,324.512 200,312.5C 217.771,265.192 234.771,217.526 251,169.5C 254.928,161.47 261.095,156.137 269.5,153.5 Z M 363.5,153.5 C 386.471,154.134 397.804,165.801 397.5,188.5C 379.653,238.199 361.487,287.866 343,337.5C 331.521,350.365 318.687,351.865 304.5,342C 295.198,334.345 291.698,324.512 294,312.5C 311.771,265.192 328.771,217.526 345,169.5C 348.928,161.47 355.095,156.137 363.5,153.5 Z" /></g>
            </svg>
        </div >
    );

}

export default WnsLogo;
