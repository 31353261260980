import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GetSkeletons, ItemsList } from "../../4.marketplace/partials/items/partials/partials/itemsList";


const CategoryItems = ({ }) => {


    return (
        <div>
            <ItemsList url="/database/categories/items/get"
                category={window.location.pathname.split("/")[2]}
                contractAddress=""
                currentAddress=""
                defaultSorting="desc"
                sortingType="short"
                defaultRowItems={7} />
        </div>
    )
}

export default CategoryItems;

/*
const CategoryItems = ({ }) => {
const [sorting, setSorting] = useState("desc");
const [items, setItems] = useState([]);
const [hasMore, setHasMore] = useState(true);
const [skeletonLoading, setSkeletonLoading] = useState(true);
const [rowitems, setRowitems] = useState(7);

async function fetchNext(param, row) {
if (hasMore == true) {
setSkeletonLoading(true);
console.log(sorting);
let result = await callW3Api("/database/categories/items/get", {
category: window.location.pathname.split("/")[2],
sorting: sorting,
limit: row == null ? rowitems * 4 : row * 4,
skip: param == "init" ? 0 : items.length
})
console.log(result);
if (result.length == 0) {
setHasMore(false);
} else {
let costsPromise = []
for (let i = 0; i < result.length; i++) {
    costsPromise.push(usdToEth(result[i].cost));
}
let costs = await Promise.all(costsPromise);

for (let i = 0; i < result.length; i++) {
    result[i].cost = costs[i] * 1e18;
}
console.log(result);
setItems(param == "init" ? [...result] : [...items, ...result]);
}
setSkeletonLoading(false);
}
}

useEffect(() => {
let row = window.innerWidth < 650 ? 2 : window.innerWidth < 860 ? 4 : window.innerWidth < 1060 ? 5 : window.innerWidth < 1260 ? 6 : 7;
setRowitems(row);
setHasMore(true);
setItems([])
fetchNext("init", row);
}, [sorting])

return (
<div>
<div style={{ paddingBottom: "5%", paddingTop: "2%" }} >
<UpperTabs sorting={sorting} setSorting={setSorting} type="short" />
<InfiniteScroll
    dataLength={items.length}
    next={fetchNext}
    hasMore={hasMore}>
    <div style={{ display: "grid", gridTemplateColumns: `repeat(${rowitems}, 1fr)`, gap: window.innerWidth < 650 ? "20px" : "40px", marginTop: window.innerWidth < 650 ? "2%" : "1%" }}>
        {items.map((item, index) => {
            return (
                <div key={index}>
                    <ItemsBox item={item} membership={window.location.pathname.includes("memberhsip") ? true : false} />
                </div>
            )
        })}
    </div>
</InfiniteScroll>
</div>
<div style={{ display: skeletonLoading ? "block" : "none" }}>
<div style={{ display: "grid", gridTemplateColumns: `repeat(${rowitems}, 1fr)`, gap: window.innerWidth < 650 ? "20px" : "40px", marginTop: window.innerWidth < 650 ? "2%" : "1%" }}>
    <GetSkeletons paramsArray={[...Array(rowitems).keys()]} />
</div>
</div>
</div >
);

}

export default CategoryItems;
*/