import React from "react";
import CategoryHeader from "./partials/header";
import CategoryItems from "./partials/items";

class Category extends React.Component {
    componentDidMount() {

    }

    render() {

        return (
            <div className="w-full flex-1 flex justify-center bg-black" id="mainpart">
                <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                    <div className="w-full">
                        <CategoryHeader />
                        <CategoryItems />
                    </div>
                </div >
            </div>
        );
    }
}

export default Category;
