import { useConnectModal } from "@rainbow-me/rainbowkit";
import React, { useState, useEffect, useContext } from "react";
import { useAccount } from "wagmi";
import { Variables } from "../../1.resources/2.js/0.global/2.contracts/variables";
import CartMain from "./partials/main";
import CartSidebar from "./partials/sidebar";
import { callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";

const Cart = ({ }) => {
    const { openConnectModal } = useConnectModal();
    let { address } = useAccount();
    const [currentAddress, setCurrentAddress] = useState("");
    const [cart, setCart] = useState([]);
    const [cartLoading, setCartLoading] = useState(true);

    async function checkCart() {
        let cart = await callW3Api("/database/marketplace/cart/get/all", { address: address });
        console.log(cart);
        setCart(cart.items);
        setCartLoading(false);
    }

    async function init() {
        setCurrentAddress(address);
        if (address == "" || address == null) {
            openConnectModal();
        } else {
            console.log("check cart");
            checkCart();
        }
        document.getElementById("title").innerHTML = "Cart - W3 Name Service"
    }
    useEffect(() => {
        init();
    }, [])
    return (
        <div className="w-full flex-1 flex justify-center bg-black" id="mainpart">
            <div className="w-full 2xl:w-[1536px] px-0 lg:px-20 2xl:px-0  flex justify-center">
                {address != null ? (
                    <div className="w-full">
                        <div style={{ minHeight: window.innerHeight }} className="bg-black block lg:hidden ">
                            <div style={{}} className="border-y border-dark800">
                                <CartSidebar currentAddress={currentAddress} cart={cart} />
                            </div>
                            <div style={{ width: "100%" }} className="px-5 md:px-10">
                                <CartMain currentAddress={currentAddress} cart={cart} setCart={setCart} />
                            </div>

                        </div >
                        <div style={{ minHeight: window.innerHeight, justifyContent: cart.length > 0 ? "" : "center" }} className="bg-black hidden lg:flex gap-x-10 ">
                            <div style={{ width: "60%", display: cart.length > 0 ? "block" : "none" }}>
                                <CartMain currentAddress={currentAddress} cart={cart} setCart={setCart} setCartLoading={setCartLoading} />
                            </div>
                            <div style={{ width: "40%" }}>
                                <CartSidebar currentAddress={currentAddress} cart={cart} cartLoading={cartLoading} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ minHeight: window.innerHeight * 0.5 }}>
                    </div>
                )}
            </div>
        </div>
    );

}

export default Cart;
