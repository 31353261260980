import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollBox } from '../../0.global/scrollbox/scrollbox';
import { callW3Api } from '../../../1.resources/2.js/0.global/3.api/callW3Api';
import ItemsBox from '../../4.marketplace/partials/items/partials/partials/itemsBox';

const Marketplace = ({ }) => {
    let items = ["Numbers", "Cities"]

    return (
        <div className="flex justify-center items-center md:pb-10 px-5 md:px-0 pt-10 md:pt-0 bg-black" id="questions">
            <div className='w-full bg-black rounded-0 flex justify-center py-0 '>
                <div className='w-full' id="" >
                    {/* <p className='font-semibold text-5xl md:text-6xl text-start md:text-center px-5 md:px-0 text-white'>Questions?</p>
                    <p className='text-2xl text-start md:text-center text-dark500 mt-4 px-5 md:px-0'>We're here to help</p> */}

                    <div className='mt-10 px-0'>
                        {items.map((item, index) => (
                            <div className='w-full' key={index}>
                                <Mapping item={item} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marketplace;

const Mapping = ({ item }) => {
    const [items, setItems] = useState([])

    async function init() {
        let result = await callW3Api("/database/categories/items/get", { category: item.toLowerCase(), limit: 5, skip: 0, sorting: "desc" });
        console.log(result);
        setItems(result.items);
    }
    useEffect(() => {
        init();
    }, [])

    return (
        <div className='w-full mb-16'>
            <div className='flex justify-between items-center'>
                <div>
                    <p className='font-semibold text-4xl text-white'>Explore {item.toLowerCase()}</p>
                    <p className='text-md text-dark500 mt-2'>{"Popular " + item.toLowerCase() + " on WNS"}</p>
                </div>
                <a className='flex items-center gap-x-2 cursor-pointer' href={"/category/" + item.toLowerCase()}>
                    <p className='text-md text-blue-500 font-semibold'>{"View all"}</p>
                    <FontAwesomeIcon icon={['fas', 'fa-arrow-right']} className="text-blue-500 text-sm" />
                </a>
            </div>
            <div className='mt-5'>
                {items.length > 0 ? (
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-6 gap-y-6'>
                        {items.map((item, index) => (
                            <div className='' key={index}>
                                <ItemsBox item={item} index={index} />
                            </div>
                        ))}
                    </div>
                ) : (null)}
            </div>
        </div>
    )
}
