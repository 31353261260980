import React, { useState, useEffect, useContext } from "react";
import { callW3Api } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Variables } from "../../../../../../1.resources/2.js/0.global/2.contracts/variables";
import UpperTabs from "./upperTabs";
import ItemsBox from "./itemsBox";
import { colors } from "../../../../../../1.resources/1.css/colors";
import { BlockiesGif } from "../../../../../0.global/wallet/connectWallet";

export const ItemsList = ({ url, contractAddress, currentAddress, category, defaultSorting, defaultRowItems, sortingType, itemsCallback, selectCallback, floorCallback, tabsVisibility, select, type }) => {
    const [items, setItems] = useState([]);
    const [tokenIds, setTokenIds] = useState({ listedTokenIds: [], unlistedTokenIds: [] });
    const [hasMore, setHasMore] = useState(true);
    const [skeletonLoading, setSkeletonLoading] = useState(true);
    const [sorting, setSorting] = useState(defaultSorting == null ? "asc" : defaultSorting);
    const [membership, setMembership] = useState(false);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchNext(param) {
        console.log("hasMore: " + hasMore);
        if (hasMore == true || param == "init") {
            setSkeletonLoading(true);
            let result = await callW3Api(url, {
                address: currentAddress,
                contractAddress: contractAddress,
                category: category,
                sorting: sorting,
                limit: defaultRowItems * 5,
                skip: param == "init" ? 0 : items.length,
                tokenIds: param == "init" ? { listedTokenIds: [], unlistedTokenIds: [] } : tokenIds
            })
            console.log(result);
            if (result.items.length == 0) {
                setHasMore(false);
            } else {
                setItems(param == "init" ? [...result.items] : [...items, ...result.items]);
                setTokenIds(result.tokenIds);
            }
            setSkeletonLoading(false);
            if (param == "init" && sorting == "asc" && floorCallback != null) {
                floorCallback(result.items);
            }
            if (param == "init" && itemsCallback != null) {
                itemsCallback(result.items);
            }
            setLoading(false);
        }
        console.log("hasMore: " + hasMore);
    }

    useEffect(() => {
        if (contractAddress.toLowerCase() == Variables().wnsMembershipAddr.toLowerCase()) {
            setMembership(true);
        } else {
            setMembership(false);
        }
        setHasMore(true);
        setItems([])
        setTokenIds({ listedTokenIds: [], unlistedTokenIds: [] });
        fetchNext("init");
    }, [sorting, contractAddress])

    useEffect(() => {
        if (selectCallback) {
            let array = []
            for (let i = 0; i < selected.length; i++) {
                array.push(items[selected[i]]);
            }
            selectCallback(array);
        }
    }, [selected]);


    function toggleSelected(index) {
        let array = [...selected];
        if (array.includes(index)) {
            removeItemAll(array, index);
        } else {
            array.push(index);
        }
        setSelected(array);
    }

    function next() {
        console.log("next")
    }

    useEffect(() => {
        fetchNext("init");
    }, [])
    return (
        <div style={{ paddingBottom: "5%", paddingTop: "0%" }} >
            {/* {tabsVisibility == false ? (
                <div />
            ) : (
                <UpperTabs sorting={sorting} setSorting={setSorting} type={sortingType} />
            )} */}



            {!loading && items.length == 0 ? (
                <NoItems />
            ) : (
                <div>
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchNext}
                        hasMore={hasMore}>
                        <div className={type == null ? "grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8 pt-5" : type == "short" ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 md:gap-8 pt-5" : "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 pt-5"}>
                            {items.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <ItemsBox item={item} index={index} membership={membership} select={select} toggleSelected={toggleSelected} selected={selected} />
                                    </div>
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            )}

            <div style={{ display: skeletonLoading ? "block" : "none" }}>
                <div className={type == null ? "grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8 pt-5" : type == "short" ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 md:gap-8 pt-5" : "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 pt-5"}>
                    <GetSkeletons paramsArray={[...Array(defaultRowItems).keys()]} />
                </div>
            </div>
        </div >
    );

}

export const NoItems = () => {
    return (
        <div className="flex justify-start items-start w-full h-full">
            <div className="flex flex-col justify-center items-center bg-dark900 w-full py-16 px-16 md:px-20 mt-6 rounded-2xl" style={{}}>
                <div className="block md:flex items-center gap-x-10">
                    <BlockiesGif />
                    <div>
                        <p className="text-2xl font-bold mt-8 md:mt-0 text-white">Oops, no items found!</p>
                        <p className="text-md text-dark500 mt-2">{"There are no items to show"}</p>
                        {/* {address?.toLowerCase() == userAddress?.toLowerCase() ? (
                                    <div className="text-main font-semibold flex items-center gap-x-2 mt-6 md:mt-3" onClick={() => window.location = "/search"}>
                                        <p>Search</p>
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-main" />
                                    </div>
                                ) : (null)} */}

                    </div>
                </div>
            </div>
        </div>
    )
}
export const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{ borderRadius: "12.5px" }} className={"border-2 border-dark800 bg-dark900 animate-pulse py-36"}>

            </div>
        )
    }
    );
    return Mapping;
}




function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}