import React, { useContext, useEffect } from "react";
import MembershipImg from "../../../1.resources/3.files/images/membership.gif";

const Image = ({ domain, registered, tokenId }) => {

    useEffect(() => {
        console.log(domain);
        console.log(tokenId)
    });

    return (
        <div className="">
            <img src={
                window.location.pathname.includes("name") ?
                    process.env.REACT_APP_API_URL + (registered ? `/metadata/images/tokenid=${tokenId}?800` : `/metadata/generateimage=${domain}.web3?800`)
                    : MembershipImg}
                className="w-full rounded-2xl" />
        </div >
    );

}

export default Image;
