import React, { useEffect, useState, useContext } from "react";
import { callW3Api, getWnsDomain } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { timeToString } from "../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";
import makeBlockie from "ethereum-blockies-base64";
import { zeroAddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import { calculateZeroes, formatinusd, usdToEth } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
const punycode = require('punycode/');

const Holders = ({ }) => {
    const [array, setArray] = useState([])
    const [sort, setSort] = useState("num_domains");
    const [more, setMore] = useState(false);

    async function getArray() {
        let result = await callW3Api("/database/holders/top", { sort: sort });
        console.log(result);
        setArray(result);
    }

    useEffect(() => {
        getArray();
    }, [sort])

    return (
        <div className="flex justify-center items-center w-full">
            <div className="mt-0 w-full py-6 pt-10">
                <div className="pb-4 border-b-0 border-b-light200 px-0">
                    <p className="font-semibold text-4xl text-left text-white">Top Holders</p>
                    <p className="text-dark500 text-left mt-2">Top holders of WNS domains</p>
                </div>
                <div className="w-full overflow-x-scroll">
                    <div className="w-[600px] md:w-full">
                        <Heading sort={sort} setSort={setSort} />
                        <GetMapping array={more ? array : array.slice(0, 5)} />
                        <div className="flex justify-center items-center pt-8">
                            <div className="text-blue-500 hover:text-blue-400 flex items-center gap-x-2" onClick={() => setMore(!more)}>
                                <p className=" text-sm">{more ? "Show less" : "Show more"}</p>
                                <FontAwesomeIcon icon={['fas', more ? 'fa-chevron-up' : 'fa-chevron-down']} className=" text-sm" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );

}

export default Holders;

const Heading = ({ sort, setSort }) => {

    return (
        <div className="px-0">
            <div className="flex items-center justify-between pt-2 pb-2 rounded-2xl">
                <div className="w-4/12 flex items-center gap-x-10">
                    <div className="w-[30px]">
                        <p className="text-dark500 text-md font-semibold">#</p>
                    </div>
                    <p className="text-dark500 text-md font-semibold">Account</p>
                </div>
                <div className="w-8/12 grid grid-cols-3 gap-x-4">
                    <div className={`flex items-center gap-x-2 justify-center hover:bg-dark800 py-2 rounded-full cursor-pointer ${sort == "num_domains" ? "bg-dark800" : ""}`} onClick={() => setSort("num_domains")}>
                        <p className="text-dark500 text-center font-semibold">Domains</p>
                        <FontAwesomeIcon icon={['fas', 'fa-sort']} className="text-dark500 text-sm" />
                    </div>
                    <div className={`flex items-center gap-x-2 justify-center hover:bg-dark800 py-2 rounded-full cursor-pointer ${sort == "value_eth" ? "bg-dark800" : ""}`} onClick={() => setSort("value_eth")}>
                        <p className="text-dark500 text-center font-semibold">Registration Value</p>
                        <FontAwesomeIcon icon={['fas', 'fa-sort']} className="text-dark500 text-sm" />
                    </div>
                    <div className={`flex items-center gap-x-2 justify-center hover:bg-dark800 py-2 rounded-full cursor-pointer ${sort == "value_usd" ? "bg-dark800" : ""}`} onClick={() => setSort("value_usd")}>
                        <p className="text-dark500 text-center font-semibold">Registration Value <br /> (in USD)</p>
                        <FontAwesomeIcon icon={['fas', 'fa-sort']} className="text-dark500 text-sm" />
                    </div>
                </div>

            </div>
        </div>
    )
}

const GetMapping = ({ array }) => {

    return (
        <div className="px-0">
            {array.map((item, index) => (
                <a key={index} className={`flex items-center justify-between py-6 rounded-2xl cursor-pointer ${index == 0 ? "hover:bg-dark800" : "hover:bg-dark800"}`} href={"https://opensea.io/" + item.owner} target="_blank">
                    <div className="w-4/12 flex items-center gap-x-10">
                        <div className="w-[30px]">
                            <p className="text-dark500 text-xl font-semibold">{index + 1}</p>
                        </div>
                        <GetDomain address={item.owner} />
                    </div>
                    <div className="w-8/12 grid grid-cols-3">
                        <p className="text-white text-center font-semibold">{item?.num_domains}</p>
                        <p className="text-white text-center font-semibold">{(item?.valueInEth / 1e18).toFixed(2)} ETH</p>
                        <p className="text-white text-center font-semibold">{formatinusd(item?.valueInUsd)}</p>
                    </div>
                </a>
            ))}
        </div>
    )
}


const GetDomain = ({ address }) => {
    const [value, setValue] = useState("");
    const [isDomain, setIsDomain] = useState(false);

    async function parseDomain() {
        try {
            let domain = await getWnsDomain(address);
            if (domain != "null") {
                setValue(domain);
                setIsDomain(true);
            }
        } catch (e) { }
    }

    useEffect(() => {
        setValue(shortenaddress(address));
        if (address && address != zeroAddress) {
            parseDomain()
        }
    }, [address])

    return (
        <div>
            {isDomain ? (
                <div className="flex gap-x-6 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-10 h-10 rounded-xl" />
                    <div className="text-left">
                        <p className="text-blue-500 text-lg font-semibold" >{value}</p>
                        <p className="text-dark500 text-sm">@{shortenaddress(address)}</p>
                    </div>
                </div>
            ) : (
                <div className="flex gap-x-6 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-10 h-10 rounded-xl" />
                    <div className="text-left">
                        <p className="text-blue-500 text-lg font-semibold" >{value}</p>
                        <p className="text-dark500 text-sm">@{shortenaddress(address)}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
