import React, { useState, useEffect, useContext } from "react";

import { Variables } from "../../../../1.resources/2.js/0.global/2.contracts/variables";

import { ItemsList } from "../../../4.marketplace/partials/items/partials/partials/itemsList";

const Wishlist = ({ currentAddress }) => {
    const [domain, setDomain] = useState("");


    useEffect(() => {
        //init();
    }, [currentAddress])
    return (
        <div style={{ paddingTop: "2%" }} className="h-full">

            <div className="px-5 md:px-12 py-6">
                <p className="font-bold text-2xl">/ Wishlist</p>
            </div>



            <div style={{}} className="bg-gray-100 px-5 md:px-12 border-t-2 border-gray-200 min-h-screen rounded-b-3xl">
                <ItemsList url="/database/account/wishlist/get"
                    currentAddress={currentAddress}
                    contractAddress={Variables().wnsErc721Addr}
                    defaultSorting="desc"
                    defaultRowItems={6}
                    sortingType="short"
                    type={"short2"} />
                {/*  <DomainsListings currentAddress={currentAddress} /> */}
            </div>
        </div >
    );

}

export default Wishlist;
