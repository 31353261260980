import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoryHeader = ({ }) => {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let url = window.location.pathname;
    let slog = url.substring(url.lastIndexOf("/") + 1);
    let title;
    if (slog == "us-states") {
        title = "States (US)"
    } else if (slog == "premium") {
        title = "Premium Domains"
    } else {
        title = capitalizeFirstLetter(url.substring(url.lastIndexOf("/") + 1));
    }
    document.getElementById("title").innerHTML = title + " - W3 Name Service";

    return (
        <div style={{ marginBottom: "0%" }}>
            <div style={{ marginTop: "2%" }} >
                <p className="font-bold text-5xl" >
                    <span id="primarynameext" style={{ color: "#fff" }}>{title}</span>
                </p>
                <p className="text-dark500 mt-4">Explore domains in this category</p>
            </div>

        </div>
    );
}


export default CategoryHeader;
