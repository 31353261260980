import React from 'react';
import Logo from "../../../1.resources/3.files/logo/logo_color.webp";
import HeaderLinks from './headerLinks';
import HeaderRight from './headerRight';
import HeaderSearch from './headerSearch';
import Img1 from "../../../1.resources/3.files/images/ginny.png";
import Img2 from "../../../1.resources/3.files/images/earth.png";
import Img3 from "../../../1.resources/3.files/images/john4.png";
import Img4 from "../../../1.resources/3.files/images/colors.png";
import Img5 from "../../../1.resources/3.files/images/ruby.png";
import Img6 from "../../../1.resources/3.files/images/zoey.png";
import Img7 from "../../../1.resources/3.files/images/michael.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MobileSidebar from './mobileSidebar';
import MobileSearch from './mobileSearch';

let carouselItems = [
    { "index": "1", "image": Img1 },
    { "index": "2", "image": Img2 },
    { "index": "3", "image": Img3 },
    { "index": "4", "image": Img4 },
    { "index": "5", "image": Img5 },
    { "index": "6", "image": Img6 },
    { "index": "7", "image": Img7 },


]
const Header = () => {
    let items = [1, 2, 3, 4, 5, 6, 7]
    const [selectedImage, setSelectedImage] = React.useState(items[Math.floor(Math.random() * items.length)]);
    const [mobileSidebar, setMobileSidebar] = React.useState(false);
    const [mobileSearch, setMobileSearch] = React.useState(false);
    const [searchActive, setSearchActive] = React.useState(false);

    return (
        <div className='w-full'>



            <div className="flex lg:hidden items-center py-4" style={{ position: "relative", zIndex: "1000000000000000", justifyContent: "space-between" }}>

                <a href="/" style={{ display: "flex", alignItems: "center", cursor: "pointer" }} >
                    <img src={Logo} style={{ width: "25px" }} />
                    <p style={{ color: "#fff", marginLeft: '5%', fontSize: "120%", fontWeight: "bold", whiteSpace: "nowrap" }}>W3 <span className='text-dark500 font-semibold'>Name Service</span></p>
                </a>
                <div className="onlyvertcenter">
                    <div onClick={() => setMobileSearch(!mobileSearch)}>
                        <FontAwesomeIcon icon={['fas', "fa-magnifying-glass"]} style={{ color: "#fff", fontSize: "120%" }} id="arrowicon" />
                    </div>
                    <div onClick={() => window.location = "/cart"}>
                        <FontAwesomeIcon icon={['fas', "fa-shopping-bag"]} style={{ color: "#fff", fontSize: "120%", marginLeft: "20px" }} id="arrowicon" />
                    </div>
                    <div onClick={() => setMobileSidebar(!mobileSidebar)} style={{ marginLeft: "20px" }}>
                        <FontAwesomeIcon icon={['fas', 'fa-bars']} style={{ color: "#fff", fontSize: "120%" }} id="arrowicon" />
                    </div>
                </div>
            </div>


            <div className="w-full hidden lg:block">
                <div className="py-3 flex items-center justify-between gap-x-4 w-full">
                    <div className="flex flex-none">
                        <a href="/" className="cursor-pointer">
                            <img src={Logo} className="w-12 h-12 bg-black rounded-xl" />
                        </a>
                    </div>

                    <div className="flex items-center gap-x-4  w-full pl-5">
                        <HeaderSearch searchActive={searchActive} setSearchActive={setSearchActive} />


                    </div>
                    <div className="hidden lg:block px-5">
                        <HeaderLinks />
                    </div>
                    <HeaderRight />
                </div>
                <div className="w-full flex items-center justify-start md:justify-center flex lg:hidden overflow-x-scroll pb-2 noscrollbars">
                    <div className="">
                        <HeaderLinks />
                    </div>
                </div>
            </div>
            {/* <Banner /> */}
            {/* <div className='hidden lg:block' >
                <header className="bg-white py-3 flex justify-between items-center px-5 md:px-20 border-b-2 border-b-gray-200">
                    <div className='w-7/12 flex items-center gap-x-0'>
                        <img src={Logo} alt="Logo" className="w-12 h-12 bg-black cursor-pointer" style={{ borderRadius: "16px" }} onClick={() => window.location = "/"} />

                        <div id="" className='w-full pl-8'>
                            <HeaderSearch searchActive={searchActive} setSearchActive={setSearchActive} />
                        </div>
                    </div>
                    <div className='w-5/12 flex items-center justify-between'>
                        <div className='w-full'>
                            <HeaderLinks />
                        </div>

                        <div className="flex items-center justify-end">
                            <HeaderRight />
                        </div>
                    </div>
                </header>
            </div> */}


            <div style={{ width: "100%" }} className={window.innerWidth < 1000 ? (mobileSidebar || mobileSearch ? "slider slide-in block lg:hidden static top-[58px] " : "slider slide-out block lg:hidden static top-[58px]") : ""}>
                {mobileSidebar ? (
                    <MobileSidebar setMobileSidebar={setMobileSidebar} />
                ) : (<div />)}
                {mobileSearch ? (
                    <MobileSearch mobileSearch={mobileSearch} setMobileSearch={setMobileSearch} />
                ) : (<div />)}
            </div>
        </div >
    )
}

export default Header;
