import React, { useState, useEffect, useContext } from "react";
import Footer from "./2.views/0.global/footer/footer";
import Header from "./2.views/0.global/header/header";
/* import Home from "./3.views/1.home/home";
import Footer from "./3.views/1.home/partials/footer";
import Hero from "./3.views/1.home/partials/hero";
import Recent from "./3.views/1.home/partials/recent";
import Wns from "./3.views/1.home/partials/wns";
import Account from "./3.views/5.account/account";
import { ThemeContext } from "./3.views/theme";
import LandingImg2 from "./1.resources/3.images/images/bg2.png";
import { getcurrentaddress } from "./1.resources/2.js/0.global/1.loginsystem/connectweb3"; */
import Home from "./2.views/1.home/home";
import Category from "./2.views/2.categories/category";
/* import Category from "./2.views/2.categories/category";
 */import Token from "./2.views/3.tokens/token";
import Marketplace from "./2.views/4.marketplace/marketplace";
import Cart from "./2.views/6.cart/cart";
import Account from "./2.views/5.account/account";
import WnsToken from "./2.views/7.token/token";
import Vote from "./2.views/8.vote/vote";
/* import Marketplace from "./2.views/4.marketplace/marketplace";
import Account from "./2.views/5.account/account";
import Cart from "./2.views/6.cart/cart"; */
const Main = ({ }) => {

  const [connectModal, setConnectModal] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [path, setPath] = useState();
  /* async function init() {
    let currentAddress = await getcurrentaddress();
    console.log(currentAddress)
    if (currentAddress != "null" && currentAddress != "" && currentAddress != null) {
      setWalletConnected(true);
    } else {
      setWalletConnected(false);
    }
  } */
  useEffect(() => {
    //init();
    let url = window.location.pathname;
    let array = url.split("/");
    console.log(array);
    if (array[1] == "") {
      setPath(0);
    } else if (array[1] == "category") {
      setPath(1);
    } else if (array[1] == "name" || array[1] == "membership") {
      setPath(2);
    } else if (array[1] == "marketplace") {
      setPath(3);
    } else if (array[1] == "account") {
      setPath(4);
    } else if (array[1] == "cart") {
      setPath(5);
    } else if (array[1] == "token") {
      setPath(6);
    } else if (array[1] == "vote") {
      setPath(7);
    }
  }, []);

  return (
    <div className="w-screen min-h-screen flex flex-col bg-black">

      {/* Header */}
      <div className={`w-full flex justify-center bg-black sticky top-0`} id="header" style={{ zIndex: 1000000000 }}>
        <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
          <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected} connectModal={connectModal} setConnectModal={setConnectModal} />
        </div>
      </div>

      {/* Main Content */}
      {path == 0 ? (
        <div>
          <Home />
        </div>
      ) : (
        // <div className="w-full flex-1 flex justify-center" id="mainpart">
        //   <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
        <div className="my-0 flex flex-1 pb-4 w-full" id="main">
          <div style={{}} className="w-full overflow-x-hidden overflow-y-hidden">
            {path == 2 ? (
              <Token connectModal={connectModal} setConnectModal={setConnectModal} />
            ) : path == 3 ? (
              <Marketplace />
            ) : path == 1 ? (
              <Category />
            ) : path == 5 ? (
              <Cart />
            ) : path == 4 ? (
              <Account setConnectModal={setConnectModal} />
            ) : path == 6 ? (
              <WnsToken />
            ) : path == 7 ? (
              <Vote />
            ) : (<div />)
            }


          </div>
          {/* </div >
          </div > */}
        </div >
      )}


      <div className="">
        {path == 0 ? (
          < Footer />
        ) : (null)}
      </div>
    </div >

    // <div className={window.innerWidth < 800 ? "overflow-x-hidden" : ""}>
    //   {path != null ? (

    //     <div>
    //       <div>
    //         <div className="sticky top-0" style={{ zIndex: 100000000 }}>
    //           <Header walletConnected={walletConnected} setWalletConnected={setWalletConnected} connectModal={connectModal} setConnectModal={setConnectModal} />
    //         </div>
    // <div style={{}} className="">
    //   {path == 0 ? (
    //     <Home />
    //   ) : path == 2 ? (
    //     <Token connectModal={connectModal} setConnectModal={setConnectModal} />
    //   ) : path == 3 ? (
    //     <Marketplace />
    //   ) : path == 1 ? (
    //     <Category />
    //   ) : path == 5 ? (
    //     <Cart />
    //   ) : path == 4 ? (
    //     <Account setConnectModal={setConnectModal} />
    //   ) : path == 6 ? (
    //     <WnsToken />
    //   ) : path == 7 ? (
    //     <Vote />
    //   ) : (<div />)
    //   }
    // </div>
    //         <div className="">
    //           < Footer />
    //         </div>
    //       </div>


    //     </div>
    //   ) : (
    //     <div />
    //   )
    //   }
    // </div >
  );

}

export default Main;


/* position: "absolute", top: "50%", transform: "translateY(-50%)" */