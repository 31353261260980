import { ethers } from "ethers";
import { Variables } from "../../../../../1.resources/2.js/0.global/2.contracts/variables";
import { callW3Api } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { useAccount } from "wagmi";
import { signMessage } from '@wagmi/core'
import Web3 from "web3";
import { getProvider } from '@wagmi/core'

import { verifyMessage } from 'ethers/lib/utils'


export async function listofferItem(cost, expiration, tokenId, param, currentAddress) {
    let params = await getOrderSignature(cost, expiration, tokenId, currentAddress);
    console.log(params);
    if (params != "null") {
        if (param == "List") {
            let result = await callW3Api("/database/marketplace/listings/post", params);
            return result;
        } else if (param == "Offer") {
            let result = await callW3Api("/database/marketplace/offers/post", params);
            return result;
        }
    } else {
        return "null";
    }
}

export const getOrderSignature = async (cost, expiration, tokenId, currentAddress) => {
    try {
        console.log(expiration, Date.now())
        let orderParams = {
            address: currentAddress,
            contractAddress: window.location.pathname.includes("name") ? Variables().wnsErc721Addr : window.location.pathname.includes("membership") ? Variables().wnsMembershipAddr : "",
            tokenId: tokenId,
            cost: String(ethers.utils.parseUnits(cost.toString(), 'ether')),
            orderExpiration: Date.now() + expiration
        }
        console.log(orderParams);

        let encoded = ethers.utils.defaultAbiCoder.encode(
            ['address', 'address', 'uint256', 'uint256', 'uint256'],
            [orderParams.address, orderParams.contractAddress, orderParams.tokenId, orderParams.cost, orderParams.orderExpiration]);

        let hash = ethers.utils.keccak256(encoded);
        console.log(hash);

        const orderSignature = await signMessage({ message: ethers.utils.arrayify(hash) })
        console.log(orderSignature);
        let verify = verifyMessage(hash, orderSignature);
        console.log(verify);

        orderParams["orderSignature"] = orderSignature;



        return orderParams;
    } catch (e) {
        return "null";
    }
}