import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollBox } from '../../0.global/scrollbox/scrollbox';

const Questions = ({ }) => {


    let array = [
        { question: "What is WNS?", answer: "The Web3 Name Service (WNS) is a distributed, open, and decentralized naming system that operates on the blockchain.", answer2: "WNS facilitates the mapping of various forms of machine-readable data, such as cryptocurrency addresses, content hashes, and DNS addresses, to human-readable names, including 'adam.web3.' The service provides a user-friendly and easily accessible naming system, which enhances the functionality and accessibility of the blockchain ecosystem." },
        { question: "How does it work?", answer: "The WNS ecosystem is structured through the implementation of smart contracts, which are self-executing programs designed to operate autonomously without the need for any external intermediaries.", answer2: "The core registry of WNS is deployed on the Ethereum blockchain, enabling the ecosystem to function in a trustless manner. All transactions that occur within the WNS system are validated by Ethereum validators, further ensuring the integrity and security of the ecosystem." },
        { question: "Can I transfer or sell my domain?", answer: "Yes, the domain you create is stored within your cryptocurrency wallet once you have minted it on the blockchain. From this point, you have the freedom to transfer it to any location of your choosing.", answer2: "Furthermore, there are various marketplaces available, including the WNS marketplace and external platforms such as OpenSea, where you have the option to list and sell your domain directly to interested parties or accept offers from potential buyers." },
        { question: "How much does it cost?", answer: "The pricing of WNS domains is structured based on the character length of the domain name. Additionally, certain domains are classified as 'premium' due to their rarity and are priced at a higher cost. The cost related to registering a domain will be displayed on the registration page." },
        { question: "Do I need to renew my domain?", answer: "Each WNS domain carries a one-time registration fee, and no renewal fees are associated with the domain. Once you have registered a domain, it is yours to retain." },
        { question: "Who can I contact if I need more help?", answer: "You can contact us with any queries on our Twitter page or e-mail us at contact@w3.one" },
    ]

    return (
        <div className="flex justify-center items-center pb-10 px-0 md:px-0 md:pt-20 md:pt-10 bg-black" id="questions">
            <div className='w-full bg-dark900 rounded-0 flex justify-center py-20 '>
                <div className='md:w-[768px] ' id="" >
                    <p className='font-semibold text-4xl md:text-4xl text-start md:text-center px-5 md:px-0 text-white'>Questions?</p>
                    <p className='text-xl text-start md:text-center text-dark500 mt-4 px-5 md:px-0'>We're here to help</p>

                    <div className='mt-4'>
                        {array.map((item, index) => (
                            <div key={index}>
                                <Mapping item={item} index={index} />
                            </div>
                        )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Questions;

const Mapping = ({ item }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div>
            <div className="px-5 md:px-10 lg:px-0 py-6 border-b-2 border-b-dark800 cursor-pointer hover:text-main" onClick={() => setIsOpen(!isOpen)}>
                <div className="flex justify-between items-center text-white  hover:text-main" >
                    <p className="font-semibold text-xl ">{item.question}</p>
                    <FontAwesomeIcon icon={isOpen ? "minus" : "plus"} className="" />
                </div>
                <div className={`mt-4 text-xl text-dark500 ${isOpen ? "block" : "hidden"}`}>
                    {item.answer}
                    <br />
                    <br />
                    {item.answer2}
                </div>


            </div>

        </div>
    )
}