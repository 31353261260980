import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callW3Api } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { ethToUsd, formatinusd, usdToEth } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { ethers } from "ethers";
import { getCloudProvider } from "../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getProvider } from '@wagmi/core'
import { useAccount, useConnect, useSigner } from 'wagmi'
import { ConnectWallet } from "../../0.global/wallet/connectWallet";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { colors } from "../../../1.resources/1.css/colors";
const punycode = require('punycode/');

const Price = ({ domain, registered, tokenId, owner, currentAddress, setTokenModal, setTokenParam, connectModal, setConnectModal, tokenLoading }) => {
    const provider = getProvider()
    const { openConnectModal } = useConnectModal();
    const { address, isConnecting, isDisconnected } = useAccount()
    const { data: signer, isError, isLoading } = useSigner()
    const [listing, setListing] = useState("");
    const [loading, setLoading] = useState(true);
    const [cost, setCost] = useState("");
    const [membershipCost, setMembershipCost] = useState("");
    const [usdCost, setUsdCost] = useState("");
    const [membership, setMembership] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinnerp, setSpinnerp] = useState("");
    const [linkVisible, setLinkVisbible] = useState(false);
    const [linkHref, setLinkHref] = useState("");
    const [errorVisible, setErrorVisbible] = useState(false);
    const [errorp, setErrorp] = useState("");
    const [infoVisible, setInfoVisible] = useState(false);
    const [infop, setInfop] = useState("");
    const [cartIncluded, setCartIncluded] = useState(true);

    async function checkPrices() {
        console.log("ssadasdsd")
        console.log(currentAddress)
        if (registered == true && tokenId != "" && currentAddress != "") {
            let contractAddress = window.location.pathname.includes("name") ? Variables().wnsErc721Addr : (window.location.pathname.includes("membership") ? Variables().wnsMembershipAddr : "")
            console.time("sacjkasjcas")
            let listing = await callW3Api("/database/marketplace/listings/get", { tokenId: tokenId, contractAddress: contractAddress });
            console.timeEnd("sacjkasjcas")
            console.log(listing);
            if (listing != "null") {
                setListing(listing);
                setCost(listing.cost / 1e18);
                setUsdCost(await ethToUsd(listing.cost / 1e18))
            }
            setLoading(false);
        } else if (registered == false && currentAddress != "") {
            console.log("sdsd")
            console.log(currentAddress);
            let member = "false";
            console.time("sacjkasjcas")
            if (currentAddress != null) {
                let membershipResult = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsMembershipContract.balanceOf(currentAddress);
                if (membershipResult > 0) { setMembership(true); member = "true"; }
            }
            console.timeEnd("sacjkasjcas")

            console.time("ascascascwww22")

            let costResult = await callW3Api("/database/get/cost", { name: punycode.toUnicode(domain), membership: member });
            let ethCosts = await Promise.all([usdToEth(costResult.cost), usdToEth(costResult.membershipCost == "null" ? "0" : costResult.membershipCost)]);
            console.timeEnd("ascascascwww22")
            console.log(punycode.toUnicode(domain), costResult);
            console.log(ethCosts);
            setCost(ethCosts[0]);
            setMembershipCost(ethCosts[1]);
            setUsdCost(costResult.membershipCost == "null" ? costResult.cost : costResult.membershipCost);
            setLoading(false);

            let cart = await callW3Api("/database/marketplace/cart/get", { address: currentAddress });
            if (cart != "null") {
                if (cart.names.includes(domain + ".web3")) {
                    setCartIncluded(true);
                } else {
                    setCartIncluded(false);
                }
            } else {
                setCartIncluded(false);
            }
        }
    }

    useEffect(() => {
        checkPrices();
    }, [registered, tokenId, currentAddress]);

    async function addToCart() {
        setSpinnerp("Adding to your cart...")
        setSpinnerVisible(true);
        let result = await callW3Api("/database/marketplace/cart/update", { address: currentAddress, names: [domain + ".web3"] });
        setSpinnerVisible(false);
        setInfop("Successfully added to cart.")
        setInfoVisible(true);
        setCartIncluded(true);
    }

    async function register() {
        setSpinnerp("Please wait...")
        setSpinnerVisible(true);
        let params = await callW3Api("/database/tokens/register/getParams", { address: currentAddress, name: domain.toLowerCase(), couponName: "" });
        console.log(params);
        let tuple = [[params.msg.name, params.msg.extension, params.msg.registrant, params.msg.cost, params.msg.expiration, params.msg.splitAddresses, params.msg.splitAmounts]];
        let signArray = [params.signature];
        console.log(tuple);

        try {
            const wnsRegistrarContract = new ethers.Contract(Variables().wnsRegistrarAddr, Variables().wnsRegistrarAbi, signer);
            setSpinnerp("Confirm the transaction in your wallet...")
            const tx = await wnsRegistrarContract.wnsRegister(tuple, signArray, { value: params.cost });
            setSpinnerVisible(false);
            setLinkVisbible(true);
            setLinkHref("https://etherscan.io/tx/" + tx.hash);
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                window.location.reload();
            });
        } catch (e) {
            console.log();
            setSpinnerVisible(false);
            if (e.message.includes("insufficient funds")) {
                setErrorp("Insufficient funds.")
            } else {
                setErrorp("Something went wrong.")
            }
            setErrorVisbible(true);
        }
    }

    async function buynow(param) {
        try {
            setSpinnerp("Please wait...")
            setSpinnerVisible(true);
            let params = await callW3Api("/database/marketplace/buy/getParams", { address: currentAddress, listing: listing, param: param });
            console.log(params);
            let tuple = [[params.msg.from, params.msg.to, params.msg.orderAddress, params.msg.contractAddress, params.msg.tokenId, params.msg.cost, params.msg.royalty, params.msg.paymentToken, params.msg.orderExpiration, params.msg.wnsExpiration, params.msg.orderSignature, params.signature]];
            console.log(tuple);
            const mainContract = new ethers.Contract(Variables().wnsMarketplaceAddr, Variables().wnsMarketplaceAbi, signer);
            setSpinnerp("Confirm the transaction in your wallet...")
            const tx = await mainContract.wnsMatch(tuple, { value: String(params.msg.cost) });
            setSpinnerVisible(false);
            setLinkVisbible(true);
            setLinkHref("https://etherscan.io/tx/" + tx.hash);
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                window.location.reload();
            });
        } catch (e) {
            setSpinnerVisible(false);
            setErrorp("Something went wrong.")
            setErrorVisbible(true);
        }
    }

    async function cancelListing() {
        setSpinnerp("Please wait...")
        setSpinnerVisible(true);
        const mainContract = new ethers.Contract(Variables().wnsMarketplaceAddr, Variables().wnsMarketplaceAbi, signer);
        setSpinnerp("Confirm the transaction in your wallet...")
        const tx = await mainContract.cancelOrder([listing.orderSignature]);
        setSpinnerVisible(false);
        setLinkVisbible(true);
        setLinkHref("https://etherscan.io/tx/" + tx.hash);
        getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
            window.location.reload();
        });
        try {
        } catch (e) {
            setSpinnerVisible(false);
            setErrorp("Something went wrong.")
            setErrorVisbible(true);
        }
    }

    return (
        <div className="bg-dark800 mt-8 rounded-2xl px-10 py-10 border border-dark800">
            {/* <div className="p-3 px-4 rounded-t-xl bg-gray-50 flex justify-between shadowDiv">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-tag']} className="text-dark500" style={{ fontSize: "90%" }} />
                    <p className="font-semibold text-dark500 ml-2 text-md">{registered ? "Listing" : "Register"}</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}
            {!tokenLoading ? (
                <p className="font-semibold text-white ml-0 text-xl">{registered ? "Current Price" : "Register"}</p>
            ) : (null)}
            <div className="gap-5 mt-1 rounded-b-xl" >
                {loading ? (
                    <GetSkeletons />
                ) : (
                    <div>

                        {registered ? (
                            listing != "" ? (
                                <div style={{ marginBottom: "2%", marginTop: "0%" }}>
                                    <p className="text-dark500 text-lg" >Listed by the owner</p>
                                    <div className="mt-6" style={{ display: "flex", alignItems: "baseline" }} >

                                        <p className="font-bold text-4xl text-white">{cost} ETH</p>
                                        <p className="text-dark500 ml-2 text-lg">({usdCost})</p>

                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {/* <p className="text-black text-lg font-semibold" >Current Price</p> */}

                                    <p className="text-dark500 mt-1">This item is not listed</p>
                                </div>
                            )
                        ) : (
                            <div style={{ marginBottom: "2%", marginTop: "0%" }}>
                                <p className="text-dark500 text-md" >Current Price</p>
                                <div style={{ display: "flex", alignItems: "baseline" }} className="mt-4 pb-0">
                                    <p className="font-bold text-4xl text-white">{membership ? membershipCost : cost} ETH</p>
                                    <p className="font-normal text-dark500 ml-2">{formatinusd(usdCost)}</p>
                                </div>
                            </div>
                        )}
                    </div>
                )
                }
                {
                    loading ? (<div>
                    </div>) : (
                        <div style={{ paddingTop: window.innerWidth < 800 ? "5%" : "0%" }}>
                            <div>
                                <div style={{ display: spinnerVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ fontSize: "100%" }} className="text-main" spin />
                                    <p style={{ fontSize: "100%", fontWeight: "semibold", marginLeft: "1%", whiteSpace: "nowrap" }} className="text-main">{spinnerp}</p>
                                </div>
                                <div style={{ display: errorVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "100%" }} />
                                    <p style={{ fontSize: "100%", fontWeight: "semibold", color: "#D55554", marginLeft: "1%", whiteSpace: "nowrap" }}>{errorp}</p>
                                </div>
                                <div style={{ display: infoVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex  items-center">
                                    <FontAwesomeIcon icon={['fas', 'fa-check']} style={{ fontSize: "100%" }} className="text-main" />
                                    <p style={{ fontSize: "100%", fontWeight: "semibold", marginLeft: "1%", whiteSpace: "nowrap" }} className="text-main">{infop}</p>
                                </div>
                                <div style={{ display: linkVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                                    <a href={linkHref} target="_blank" style={{ fontSize: "100%", fontWeight: "semibold", whiteSpace: "nowrap" }} className="text-main">View transaction on Etherscan</a>
                                    <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} style={{ fontSize: "100%", marginLeft: "1%" }} className="text-main" />
                                </div>
                            </div>
                            <div style={{ gap: "10px" }} className="onlyvertcenter pt-4">
                                {address != null ? (

                                    registered ? (
                                        listing == "" ? (
                                            owner.toLowerCase() == currentAddress.toLowerCase() ? (
                                                <button onClick={() => { setTokenParam("List"); setTokenModal(true) }} className="text-white bg-main px-4 py-2 rounded-full" >List item</button>
                                            ) : (
                                                <button onClick={() => { setTokenParam("Make Offer"); setTokenModal(true) }} className="text-white bg-main px-4 py-2 rounded-full" >Make Offer</button>
                                            )
                                        ) : (
                                            owner.toLowerCase() == currentAddress.toLowerCase() ? (
                                                <div style={{ gap: "10px" }} className="vertcenter">
                                                    <button onClick={() => cancelListing()} className="text-white bg-main px-4 py-2 rounded-full" >Cancel listing</button>
                                                </div>
                                            ) : (
                                                <div style={{ gap: "10px" }} className="vertcenter">
                                                    <button onClick={() => { currentAddress == "null" ? setConnectModal(true) : buynow("list") }} className="text-white bg-main px-4 py-2 rounded-full" >Buy now</button>
                                                    <button onClick={() => { currentAddress == "null" ? setConnectModal(true) : setTokenModal(true); setTokenParam("Make Offer") }} className="text-main" >Make offer</button>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div>
                                            <div style={{ gap: "10px" }} className="vertcenter">
                                                <button onClick={() => { currentAddress == "null" ? setConnectModal(true) : register() }} className="text-white bg-main px-4 py-2 rounded-full" >Register</button>
                                                <button onClick={() => { currentAddress == "null" ? setConnectModal(true) : (addToCart()) }} style={{ display: cartIncluded ? "none" : "block" }} className="text-main" >Add to Cart</button>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <ConnectWallet />
                                )
                                }
                            </div>
                            {membership ? (
                                <div style={{}} className="onlyvertcenter pb-0 mt-8">
                                    <div style={{ borderRadius: "50px", width: "16px", height: "16px" }} className="bg-dark500 vertcenter">
                                        <FontAwesomeIcon icon={['fas', 'fa-check']} style={{ color: "#fff", fontSize: "60%", marginTop: "0%" }} />
                                    </div>
                                    <p className="text-dark500 text-md ml-2">Membership pass applied ({parseInt(membershipCost / cost * 100)}% savings)</p>
                                </div>
                            ) : (<div />)}
                        </div>
                    )
                }


            </div >

        </div >
    );

}

export default Price;


const GetSkeletons = ({ }) => {

    return (
        <div>
            <div className="w-60 h-10 rounded-full bg-dark700 animate-pulse mt-6" />
        </div>
    )
}