import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { coinsJson } from "../../../../1.resources/2.js/0.global/0.smallfunctions/coins";
import CloudContracts from "../../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import ManageModal from "./modal/manageModal";
import { callW3Api } from "../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { Variables } from "../../../../1.resources/2.js/0.global/2.contracts/variables";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { uploadImage } from "./partials/image";
import { ethers } from "ethers";
import { getCloudProvider } from "../../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { useSigner } from "wagmi";
import { colors } from "../../../../1.resources/1.css/colors";
import { NoItems } from "../../../4.marketplace/partials/items/partials/partials/itemsList";
const punycode = require('punycode/');

const Manage = ({ currentAddress, content, setContent }) => {
    const [coins, setCoins] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [domains, setDomains] = useState([]);
    const [currentDomain, setCurrentDomain] = useState(0);
    const [loading, setLoading] = useState(true);

    async function init() {
        if (currentAddress != "" && currentAddress != "null") {
            setDomains(await callW3Api("/database/account/tokens/get/all", { address: currentAddress, contractAddress: Variables().wnsErc721Addr }));
            setLoading(false);
        }
    }
    useEffect(() => {
        init();
    }, [])
    return (
        <div style={{}} className="pt-6" >

            <div className="px-5 md:px-12">
                <p style={{ fontSize: "100%", fontWeight: "bold", fontWeight: "bold", color: "#fff" }}>/ Manage Domains</p>



            </div>
            <div className="mt-6  bg-dark900  px-5 md:px-0 pt-10 border-t-2 border-t-dark800 pb-10 rounded-b-3xl flex justify-center h-full" >

                {!loading && domains.length == 0 ? (
                    <div className="w-full h-screen">
                        <NoItems />
                    </div>
                ) : (
                    <div className="w-full md:px-20">
                        <div onClick={() => setModalVisible(true)} className="flex justify-between items-center border-2 border-dark800 rounded-3xl p-4 px-5 md:px-16 mt-0 bg-dark800 mb-8 cursor-pointer">
                            <div>
                                <p className="text-dark500 text-md mb-1" >Managing</p>
                                {domains.length == 0 ? (
                                    null
                                ) : (
                                    <p className="text-xl font-semibold text-white">{domains.length == 0 ? "Loading your tokens..." : punycode.toUnicode(domains[currentDomain].domain) + domains[currentDomain].extension}</p>
                                )}
                            </div>
                            <div className="flex items-center gap-x-2 text-blue-500 hover:text-blue-400 cursor-pointer">
                                <p className="">{window.innerWidth < 800 ? "Select" : "Select domain"}</p>
                                <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="" />
                            </div>
                        </div>

                        <ManageItem domains={domains} currentDomain={currentDomain} setCurrentDomain={setCurrentDomain} currentAddress={currentAddress} />
                    </div>
                )}

            </div>
            <ManageModal currentDomain={currentDomain} domains={domains} currentAddress={currentAddress} setCurrentDomain={setCurrentDomain} modalVisible={modalVisible} setModalVisible={setModalVisible} />

        </div >
    );

}

export default Manage;

const ManageItem = ({ currentAddress, domains, currentDomain }) => {
    const { data: signer, isError, isLoading } = useSigner()

    const [coins, setCoins] = useState([]);
    const [avatar, setAvatar] = useState("");
    const [cname, setCname] = useState("");
    const [edit, setEdit] = useState(false);
    const [editJson, setEditJson] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinnerp, setSpinnerp] = useState("");
    const [linkVisible, setLinkVisbible] = useState(false);
    const [linkHref, setLinkHref] = useState("");
    const [hash, setHash] = useState("");
    const [hashLoading, setHashLoading] = useState(false);

    async function init() {
        if (currentAddress != "" && currentAddress != "null" && domains.length != 0) {
            let keys = Object.keys(coinsJson);
            console.log(coinsJson);
            console.log(keys);

            let txtPromise = []
            for (let i = 0; i < keys.length; i++) {
                txtPromise.push(CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.getTxtRecords(domains[currentDomain].tokenId, keys[i]));
            }
            txtPromise.push(CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.getTxtRecords(domains[currentDomain].tokenId, "avatar"));
            txtPromise.push(CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.getTxtRecords(domains[currentDomain].tokenId, "CNAME"));

            let txts = await Promise.allSettled(txtPromise);
            console.log(txts);
            let coinsResult = []
            let json = {}
            for (let i = 0; i < keys.length; i++) {
                coinsResult.push({
                    name: keys[i],
                    image: coinsJson[keys[i]],
                    value: keys[i] == "ETH" && txts[i].value == "" ? currentAddress : txts[i].value
                })
                json[keys[i]] = keys[i] == "ETH" && txts[i].value == "" ? currentAddress : txts[i].value;
            }
            console.log(coinsResult)
            setCoins(coinsResult);
            setAvatar(txts[keys.length].value);
            json["avatar"] = txts[keys.length].value;
            setCname(txts[keys.length + 1].value);
            json["CNAME"] = txts[keys.length + 1].value;
            setEditJson([json]);
        }
    }
    useEffect(() => {
        setCoins([])
        init()
    }, [domains, currentDomain])

    async function save() {
        console.log(editJson);
        setSpinnerp("Please wait...")
        setSpinnerVisible(true);
        let params = await callW3Api("/database/account/txtRecords/getParams", { tokenId: domains[currentDomain].tokenId, json: editJson[0] });
        console.log(params);

        const mainContract = new ethers.Contract(Variables().wnsResolverAddr, Variables().wnsResolverAbi, signer);
        setSpinnerp("Confirm the transaction in your wallet...")
        const tx = await mainContract.setTxtRecords(params.labels, params.records, params.tokenId, params.signature);
        setSpinnerVisible(false);
        setLinkVisbible(true);
        setLinkHref("https://etherscan.io/tx/" + tx.hash);
        getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
            let details = await callW3Api("/database/get/tokenDetails", { key: "tokenId", value: domains[currentDomain].tokenId, param: "refresh" })
            window.location.reload();
        });
    }

    async function inputChange(value, param) {
        console.log(value, param);
        let json = editJson[0];
        json[param] = value;
        console.log(json);
        setEditJson([json]);
    }


    async function uploadAvatar(e) {
        console.log(e);
        console.log(e.target.files)
        if (e.target.files[0]) {
            setHashLoading(true);
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener("load", async function (e) {
                var base64 = e.target.result;
                console.log(base64);
                let hash = await uploadImage(base64, editJson, setEditJson, setHash, setHashLoading);
            });
        }

    }


    return (
        <div className="">
            <div className="rounded-3xl py-2 px-5 md:px-5">
                <div className="md:flex gap-x-16 ">
                    <div>
                        {domains.length != 0 ? (
                            <img src={domains.length != 0 ?
                                hash != "" ? process.env.REACT_APP_API_URL + `/metadata/generateimageavatar=${domains[currentDomain].domain}.web3&${hash}?400` :
                                    domains[currentDomain].imageHash == null || domains[currentDomain].imageHash == "null" || domains[currentDomain].imageHash == "" ? process.env.REACT_APP_API_URL + `/metadata/generateimage=${domains[currentDomain].domain}.web3?400` :
                                        process.env.REACT_APP_API_URL + `/metadata/generateimageavatar=${domains[currentDomain].domain}.web3&${domains[currentDomain].imageHash}?400` : ""}
                                className="w-40 h-40 rounded-2xl" />
                        ) : (
                            <div className="w-40 h-40 rounded-2xl">
                                <div className="w-full aspect-square rounded-2xl bg-dark700 animate-pulse" />
                            </div>
                        )}
                    </div>
                    <div className="py-10 md:pt-0">
                        {/* <p className="text-dark500 text-md">Name</p> */}
                        <p className="text-dark500 text-lg mt-0">Token ID - #{domains[currentDomain]?.tokenId}</p>

                        <p className="font-semibold text-3xl md:text-5xl mt-2 text-white">{domains.length == 0 ? "Loading..." : punycode.toUnicode(domains[currentDomain].domain) + domains[currentDomain].extension}</p>
                        {/* <p className="text-dark500 text-md mt-2">Minted on Ethereum</p> */}

                        <a href={"/name/" + domains[currentDomain]?.domain} className="flex items-center gap-x-2 mt-4 pt-2 ">
                            <p className="text-main">View on Marketplace</p>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-main" style={{ fontSize: "90%" }} />
                        </a>
                        {/* <a href="" className="flex items-center gap-x-2 mt-0 pt-4 ">
                            <p className="text-main">View on Etherscan</p>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-main" style={{ fontSize: "90%" }} />
                        </a> */}
                        {/* <p className="font-semibold text-2xl mt-2"></p> */}
                    </div>
                </div>
                <div className="flex justify-between items-center mt-10 pt-10 border-t-2 border-dark800">
                    <div>
                        <div className="flex items-center" style={{ display: spinnerVisible ? "flex" : "none", marginBottom: "0%", marginTop: "0%" }}>
                            <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-white text-opacity-100" style={{ fontSize: "70%" }} spin />
                            <p className="text-xs font-semibold text-white ml-1 whitespace-nowrap">{spinnerp}</p>
                        </div>
                        <div className="flex items-center" style={{ display: linkVisible ? "flex" : "none", marginBottom: "0%", marginTop: "0%" }}>
                            <a href={linkHref} target="_blank" className="text-xs font-semibold text-white bluelink mr-1 whitespace-nowrap">View transaction on Etherscan</a>
                            <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} className="text-white text-opacity-100" style={{ fontSize: "70%" }} />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button onClick={() => edit ? save() : setEdit(true)} className={`bg-main text-white text-sm font-semibold px-4 py-2 rounded-full ${coins.length != 0 ? "block" : "hidden"}`}>{edit ? "Save" : "Edit"}</button>
                        <button onClick={() => edit ? setEdit(false) : ""} className={`text-main font-semibold text-sm px-4 py-2 rounded-full ${edit ? "block" : "hidden"} ml-2`}>Cancel</button>
                    </div>
                </div>
                <div className="mt-0">
                    <p className="font-semibold text-lg text-white">Avatar</p>
                    <p className="text-dark500 text-md mt-1">This domain resolves to the following records</p>
                    {coins.length == 0 ? (
                        <div className="w-full h-14 mt-6 rounded-2xl bg-dark700 animate-pulse" />

                    ) : (
                        <div className={`mt-6 p-2 px-6 rounded-2xl border-2 border-dark800 py-4 md:flex items-center`}>
                            <div style={{ width: "30%" }} className="flex items-center">
                                <FontAwesomeIcon icon={["fas", "fa-image"]} style={{ color: "#fff", fontSize: "100%" }} />
                                <p className="font-semibold text-md pl-4 text-white"  >Avatar</p>
                            </div>
                            <div className="w-full pt-5 md:pt-0">
                                {!edit ? (
                                    <p className="text-md truncate text-white">
                                        {avatar == null || avatar == "" ? "-" : avatar}
                                    </p>
                                ) : (
                                    <div className="flex items-center justify-between">
                                        {hashLoading ? (
                                            <FontAwesomeIcon icon={["fas", "fa-circle-notch"]} style={{ color: "#fff", fontSize: "100%", marginRight: "10%" }} spin />
                                        ) : (
                                            <p className="text-md text-dark500"   >{hash !== "" ? hash : avatar}</p>
                                        )}
                                        <button onClick={() => document.getElementById("fileUpload").click()} className="font-semibold text-main text-sm"   >{hash !== "" ? "Change" : "Upload"}</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                </div>
                <div className="mt-16">
                    <p className="font-semibold text-lg text-white" >Websites</p>
                    <p className="text-dark500 text-md mt-1" >This domain resolves to the following records</p>


                    {coins.length == 0 ? (
                        <div className="w-full h-14 mt-6 rounded-2xl bg-dark700 animate-pulse" />
                    ) : (
                        <div className={`mt-6 p-2 px-6 rounded-2xl border-2 border-dark800 py-4 md:flex items-center`}>
                            <div style={{ width: "30%" }} className="flex items-center">
                                <FontAwesomeIcon icon={["fas", "fa-globe"]} style={{ color: "#fff", fontSize: "100%" }} />
                                <p className="font-semibold text-md pl-4 text-white"  >CNAME</p>
                            </div>
                            <div className="w-full pt-5 md:pt-0">
                                {!edit ? (
                                    <p className="text-md truncate text-white">{cname == null || cname == "" ? "-" : cname}</p>
                                ) : (
                                    <input value={editJson[0]["CNAME"]} onChange={(e) => inputChange(e.target.value, "CNAME")} type="text" placeholder="Type your record here..." style={{ width: "100%", border: "0", outline: "none" }} className="text-md text-white placeholder:text-dark500 bg-transparent " />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-16">
                    <p className="font-semibold text-lg text-white" >Networks</p>
                    <p className="text-dark500 text-md mt-1" >This domain resolves to the following networks</p>



                    <div>
                        {coins.length == 0 ? (
                            <div>
                                <SkeletonNetworks paramsArray={[...Array(7).keys()]} />
                            </div>
                        ) : (
                            <Networks paramsArray={coins} edit={edit} inputChange={inputChange} editJson={editJson} setEditJson={setEditJson} />
                        )}
                    </div>
                </div>
                <div style={{ opacity: 0 }}>
                    <input id="fileUpload" type="file" name="myImage" onChange={(e) => uploadAvatar(e)} />
                </div>
            </div>


        </div >
    )
}

export const Networks = ({ paramsArray, edit, inputChange, editJson, setEditJson }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} className={`mt-6 p-2 px-6 rounded-2xl border-2 border-dark800 py-4 md:flex items-center`}>
                <div style={{ width: "30%" }} className="flex items-center">
                    <img src={item.image} className="rounded-full w-6" />
                    <p className="font-semibold text-md pl-4 text-white">{item.name}</p>
                </div>
                <div className="w-full pt-5 md:pt-0">
                    {!edit ? (
                        <p className="text-md truncate text-white">{item.value == null || item.value == "" ? "-" : item.value}</p>
                    ) : (
                        <input type="text" placeholder="Type your record here..." className="text-md text-white placeholder:text-dark500 ml-2 outline-none border-none bg-transparent w-full" value={editJson[0][item.name]} onChange={(e) => inputChange(e.target.value, item.name)} />
                    )}
                </div>
            </div>
        )
    }
    );

    return Mapping;
}



export const SkeletonNetworks = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index}>
                <div className="w-full h-14 mt-6 rounded-2xl bg-dark700 animate-pulse" />
            </div>
        )
    }
    );

    return Mapping;
}

