import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./styles.css";
import Modal from "react-modal";
import { cancelListing, cancelOffer, getOrderSignature, listofferItem } from "./functions/listoffer";
import CloudContracts from "../../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { Variables } from "../../../../1.resources/2.js/0.global/2.contracts/variables";
import { getCloudProvider } from "../../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { ethers } from "ethers";
import { getProvider } from '@wagmi/core'
import { useAccount } from "wagmi";
import { useSigner } from 'wagmi'
import { colors } from "../../../../1.resources/1.css/colors";

let time = [{ "p": "6 months", "timestamp": 15778800000 },
{ "p": "1 month", "timestamp": 2629800000 },
{ "p": "7 days", "timestamp": 604800000 },
{ "p": "1 day", "timestamp": 86400000 }]

const TokenModal = ({ owner, tokenModal, setTokenModal, setTokenParam, tokenParam, tokenId, tokenParam2, currentAddress }) => {

    return (
        <div >
            <Modal
                isOpen={tokenModal}
                onRequestClose={() => setTokenModal(false)}
                className="tokenModal"
                overlayClassName="myoverlay"
            >
                <div className="bg-dark900 p-8">
                    <p className="text-4xl font-bold text-white" >
                        {tokenParam == "List" ? "List item" :
                            tokenParam == "Make Offer" ? "Make offer" : ""}
                    </p>
                    <p className="mt-2 text-dark500 text-lg">Confirm to
                        {tokenParam == "List" ? " list item for sale" :
                            tokenParam == "Make Offer" ? " make offer" :
                                ""}
                    </p>

                    {tokenParam == "List" || tokenParam == "Make Offer" ? (
                        <PriceModal owner={owner} tokenParam={tokenParam} tokenId={tokenId} currentAddress={currentAddress} />
                    ) : (<div />)}
                </div>



            </Modal>

        </div >

    );

}

export default TokenModal;

const PriceModal = ({ owner, tokenParam, tokenId, currentAddress }) => {
    const { data: signer, isError, isLoading } = useSigner()

    let { address } = useAccount();
    const provider = getProvider()
    const [inputCurrency, setInputCurrency] = useState("");
    const [inputExpiration, setInputExpiration] = useState(0);
    const [inputExpirationVisible, setInputExpirationVisible] = useState(false);
    const [signInfo, setSignInfo] = useState(false);
    const [signp, setSignp] = useState("");
    const [errorInfo, setErrorInfo] = useState(false);
    const [errorp, setErrorp] = useState("");
    const [wethBalance, setWethBalance] = useState(0);

    let css = {
        selecta: "p-2 text-dark500 border-b-2 border-b-dark800"
    }

    async function continueButton() {

        if (inputCurrency != "") {
            if (tokenParam == "List") {
                if (window.location.pathname.includes("membership")) {
                    setSignInfo(true);
                    setSignp("Please wait...");
                    let approval = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsMembershipContract.isApprovedForAll(owner, Variables().wnsMarketplaceAddr);
                    console.log(approval);
                    if (approval == false) {
                        setSignp("Approve the tokens to be listed...");
                        let membershipContract = new ethers.Contract(Variables().wnsMembershipAddr, Variables().wnsMembershipAbi, signer);
                        let tx = await membershipContract.setApprovalForAll(Variables().wnsMarketplaceAddr, true);
                        setSignp("Waiting for the transaction to be mined...");
                        await getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000);
                    }
                }
                setSignInfo(true);
                setSignp("Sign the action in your wallet to confirm.");
                let result = await listofferItem(inputCurrency, time[inputExpiration].timestamp, tokenId, "List", address);
                if (result == "ok") {
                    window.location.reload();
                } else {
                    setSignInfo(false);
                    setErrorp("Something went wrong...")
                    setErrorInfo(true);
                }
            } else if (tokenParam == "Make Offer") {
                console.log("hmm")
                if (wethBalance >= inputCurrency) {

                    setSignInfo(true);
                    setSignp("Please wait...");
                    let allowance = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wethContract.allowance(currentAddress, Variables().wnsMarketplaceAddr);
                    console.log(allowance);
                    if (parseFloat(allowance.toString()) <= (parseFloat(inputCurrency) * 1e18)) {
                        let wethContract = new ethers.Contract(Variables().wethAddr, Variables().wethAbi, signer);
                        setSignp("Confirm the transaction to approve your WETH...");
                        console.log(Variables().wnsMarketplaceAddr, "1000000000000000000000000000000000000000000000")
                        let tx = await wethContract.approve(Variables().wnsMarketplaceAddr, "1000000000000000000000000000000000000000000000");
                        console.log(tx);
                        setSignp("Waiting for the transaction to be mined...");
                        await getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000);
                    }
                    setSignp("Sign the action in your wallet to confirm.");
                    let result = await listofferItem(inputCurrency, time[inputExpiration].timestamp, tokenId, "Offer", address);
                    if (result == "ok") {
                        window.location.reload();
                    } else {
                        setSignInfo(false);
                        setErrorp("Something went wrong...")
                        setErrorInfo(true);
                    }
                } else {
                    setErrorp("Offer amount is greater than your WETH balance.")
                    setErrorInfo(true);
                }
            }
        }
        try {
        } catch (e) {
            setSignInfo(false);
            setErrorp("Something went wrong...")
            setErrorInfo(true);
        }
    }

    async function checkTxs() {
        if (tokenParam == "Make Offer") {
            let currentAddress = address;
            let balance = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wethContract.balanceOf(currentAddress);
            console.log(parseFloat(balance.toString()) / 1e18);
            setWethBalance(parseFloat(balance.toString()) / 1e18)
        }
    }

    useEffect(() => {
        checkTxs();
    }, [tokenParam]);

    return (
        <div>
            <div className="mt-10" >
                <p className="text-dark500 text-md">Set a price</p>
                <div className="bg-dark900 rounded-xl flex justify-between mt-2 p-2 px-4 border-2 border-dark800">
                    <input type="number" onChange={(e) => { setInputCurrency(e.target.value) }} value={inputCurrency} placeholder="Amount" className="bg-transparent outline-none font-semibold w-full text-lg text-white"></input>
                    <div className="bg-dark900 px-2 rounded-r rounded-r-md flex items-center">
                        <p className="text-white font-semibold text-lg">{tokenParam == "List" ? "ETH" : "WETH"}</p>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <p className="text-dark500 text-md" >Set duration</p>
                <div onClick={() => setInputExpirationVisible(!inputExpirationVisible)} className="bg-dark900 rounded-xl flex justify-between items-center mt-2 p-2 px-4 border-2 border-dark800">
                    <p className="font-semibold  text-lg text-white">{time[inputExpiration].p}</p>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" />
                </div>
                <div className={"rounded-xl border-2 border-dark800 text-white"} style={{ display: inputExpirationVisible ? "block" : "none", zIndex: "100000000000", fontSize: "90%" }}>
                    <div className={css.selecta} onClick={() => { setInputExpiration(0); setInputExpirationVisible(false); }}>
                        <p style={{ cursor: "pointer" }} className="pl-2 text-lg">6 months</p>
                    </div>
                    <div className={css.selecta} onClick={() => { setInputExpiration(1); setInputExpirationVisible(false); }}>
                        <p style={{ cursor: "pointer" }} className="pl-2 text-lg">1 month</p>
                    </div>
                    <div className={css.selecta} onClick={() => { setInputExpiration(2); setInputExpirationVisible(false); }}>
                        <p style={{ cursor: "pointer" }} className="pl-2 text-lg">7 days</p>
                    </div>
                    <div className={css.selecta} onClick={() => { setInputExpiration(3); setInputExpirationVisible(false); }}>
                        <p style={{ cursor: "pointer" }} className="pl-2 text-lg">1 day</p>
                    </div>
                </div>

            </div>
            <div style={{ marginTop: "10%" }}>
                <div style={{ display: signInfo ? "flex" : "none", marginBottom: "7.5%" }} className="vertcenter">
                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: colors.main, fontSize: "90%" }} spin />
                    <p style={{ marginLeft: "1%", whiteSpace: "nowrap" }} className="text-main text-md">{signp}</p>
                </div>
                <div style={{ display: errorInfo ? "flex" : "none", marginBottom: "7.5%" }} className="vertcenter">
                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "100%" }} />
                    <p style={{ color: "#D55554", marginLeft: "1%", whiteSpace: "nowrap" }}>{errorp}</p>
                </div>
                <div className="flex">
                    <button onClick={() => continueButton()} className="bg-main text-white px-4 py-2 rounded-full">Confirm</button>
                </div>
            </div>
        </div>
    )
}