import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callW3Api } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { GetDomain } from "./activity";
import { timeToString } from "../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { ethers } from "ethers";
import { getCloudProvider } from "../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { getProvider } from '@wagmi/core'
import { useSigner } from 'wagmi'

const Offers = ({ domain, registered, tokenId, owner, currentAddress, setTokenParam, setTokenModal, setTokenParam2 }) => {
    const provider = getProvider()
    const { data: signer, isError, isLoading } = useSigner()


    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinnerp, setSpinnerp] = useState(false);
    const [linkVisible, setLinkVisbible] = useState(false);
    const [linkHref, setLinkHref] = useState("");
    const [errorVisible, setErrorVisbible] = useState(false);

    async function checkPrices() {
        if (registered == true && tokenId != "" && owner != "" && currentAddress != "") {
            let contractAddress = window.location.pathname.includes("name") ? Variables().wnsErc721Addr : (window.location.pathname.includes("membership") ? Variables().wnsMembershipAddr : "")
            let offer = await callW3Api("/database/marketplace/offers/get", { tokenId: tokenId, contractAddress: contractAddress });
            if (offer != "null") {
                setOffers(offer);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        checkPrices();
    }, [registered, tokenId, owner, currentAddress]);

    let css = {
        p: "text-white whitespace-nowrap overflow-hidden text-left font-semibold",
        headingp: "text-dark500 whitespace-nowrap overflow-hidden text-left text-sm",
        outerDiv: "flex justify-between py-1 border-b border-b-dark800",
        headingDiv: "flex justify-between pb-2 border-b border-b-dark800"

    }

    async function cancelOffer(offer) {
        try {
            setSpinnerp("Please wait...")
            setSpinnerVisible(true);
            const mainContract = new ethers.Contract(Variables().wnsMarketplaceAddr, Variables().wnsMarketplaceAbi, signer);
            setSpinnerp("Confirm the transaction in your wallet...")
            const tx = await mainContract.cancelOrder([offer.orderSignature]);
            console.log(tx);
            setSpinnerVisible(false);
            setLinkVisbible(true);
            setLinkHref("https://etherscan.io/tx/" + tx.hash);
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                window.location.reload();
            });
        } catch (e) {
            setSpinnerVisible(false);
            setErrorVisbible(true);
        }
    }

    async function acceptOffer(offer) {

        setSpinnerp("Please wait...")
        setSpinnerVisible(true);
        let params = await callW3Api("/database/marketplace/buy/getParams", { address: currentAddress, listing: offer, param: "offer" });
        console.log(params);
        let tuple = [[params.msg.from, params.msg.to, params.msg.orderAddress, params.msg.contractAddress, params.msg.tokenId, params.msg.cost, params.msg.royalty, params.msg.paymentToken, params.msg.orderExpiration, params.msg.wnsExpiration, params.msg.orderSignature, params.signature]];
        console.log(tuple);
        if (window.location.pathname.includes("membership")) {
            let approval = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsMembershipContract.isApprovedForAll(owner, Variables().wnsMarketplaceAddr);
            console.log(approval);
            if (approval == false) {
                setSpinnerp("Approve the tokens to be transferred...");
                let membershipContract = new ethers.Contract(Variables().wnsMembershipAddr, Variables().wnsMembershipAbi, signer);
                let tx = await membershipContract.setApprovalForAll(Variables().wnsMarketplaceAddr, true);
                setSpinnerp("Waiting for the transaction to be mined...");
                await getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.transactionHash, 1, 300000);
            }
        }
        const mainContract = new ethers.Contract(Variables().wnsMarketplaceAddr, Variables().wnsMarketplaceAbi, signer);
        setSpinnerp("Confirm the transaction in your wallet...")
        const tx = await mainContract.wnsMatch(tuple, { value: String(0) });
        setSpinnerVisible(false);
        setLinkVisbible(true);
        setLinkHref("https://etherscan.io/tx/" + tx.hash);
        getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
            window.location.reload();
        });
        try {
        } catch (e) {
            setSpinnerVisible(false);
            setErrorVisbible(true);
        }
    }

    return (
        <div className="mt-16 pt-8 border-t-2 border-dark800">
            {/* <div className="p-3 px-4 rounded-t-xl bg-gray-50 flex justify-between shadowDiv">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-list']} className="text-dark500" style={{ fontSize: "90%" }} />
                    <p className="font-semibold text-dark500 ml-2 text-md">Offers</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}

            <p className="font-semibold text-white ml-0 text-xl">Offers</p>
            {/* <p className="text-dark500 text-lg mt-1">Offers made on this domain</p> */}

            <div className="mt-2 rounded-b-xl" >
                {loading ? (
                    <div>
                        <div style={{ display: "grid", gridTemplateColumns: `repeat(${4}, 1fr)`, gap: "20px", marginTop: "0%" }}>
                            <GetSkeletons paramsArray={[...Array(1).keys()]} />
                        </div>
                    </div>
                ) : (
                    offers.length == 0 ? (
                        <p className="text-dark500 text-lg">This item has no offers yet.</p>
                    ) : (
                        <div>
                            <div>
                                <div style={{ display: spinnerVisible ? "flex" : "none", marginBottom: "1%", marginTop: "1%" }} className="onlyvertcenter">
                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#000", fontSize: "70%" }} spin />
                                    <p style={{ fontSize: "70%", fontWeight: "semibold", color: "#000", marginLeft: "1%", whiteSpace: "nowrap" }}>{spinnerp}</p>
                                </div>
                                <div style={{ display: errorVisible ? "flex" : "none", marginBottom: "1%", marginTop: "1%" }} className="onlyvertcenter">
                                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "70%" }} />
                                    <p style={{ fontSize: "70%", fontWeight: "semibold", color: "#D55554", marginLeft: "1%", whiteSpace: "nowrap" }}>Something went wrong</p>
                                </div>

                                <div style={{ display: linkVisible ? "flex" : "none", marginBottom: "1%", marginTop: "1%" }} className="onlyvertcenter">
                                    <a className="bluelink" href={linkHref} target="_blank" style={{ fontSize: "70%", fontWeight: "semibold", color: "#000", whiteSpace: "nowrap" }}>View transaction on Etherscan</a>
                                    <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} style={{ color: "#000", fontSize: "70%", marginLeft: "1%" }} />
                                </div>
                            </div>
                            <div style={{ fontSize: "80%" }}>
                                <div className={css.headingDiv} >
                                    <div style={{ width: "20%" }} className="onlyvertcenter">
                                        <p className={css.headingp}>From</p>
                                    </div>
                                    <div style={{ width: "20%" }} className="onlyvertcenter">
                                        <p className={css.headingp}>Price</p>
                                    </div>
                                    <div style={{ width: "20%" }} className="onlyvertcenter" >
                                        <p className={css.headingp}>Time</p>
                                    </div>
                                    <div style={{ width: "20%" }} className="onlyvertcenter">
                                        <p className={css.headingp}></p>
                                    </div>
                                </div >
                            </div>
                            <GetMapping paramsArray={offers} css={css} currentAddress={currentAddress} owner={owner} setTokenParam={setTokenParam} setTokenModal={setTokenModal} setTokenParam2={setTokenParam2} cancelOffer={cancelOffer} acceptOffer={acceptOffer} />
                        </div>
                    )
                )}
            </div>

        </div >

    );

}

export default Offers;


const GetMapping = ({ paramsArray, css, owner, currentAddress, setTokenParam, setTokenModal, setTokenParam2, cancelOffer, acceptOffer }) => {


    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} className={css.outerDiv} >
                <div style={{ width: "20%" }} className="onlyvertcenter">
                    <p className={css.p}>
                        <GetDomain address={item.address} />
                    </p>
                </div>
                <div style={{ width: "20%" }} className="onlyvertcenter">
                    <p className={css.p}>{item.cost == "0" ? "-" : item.cost / 1e18}</p>
                </div>
                <div style={{ width: "20%" }} className="onlyvertcenter" >
                    <p className={css.p}>{timeToString(String(item.orderExpiration).length == 13 ? item.orderExpiration : item.orderExpiration * 1000)}</p>
                </div>
                <div style={{ width: "20%" }} className="onlyvertcenter" >
                    {currentAddress.toLowerCase() == item.address.toLowerCase() ? (
                        <button onClick={() => cancelOffer(item)} className="mainButton" style={{ fontSize: "80%", padding: "7.5%" }}>Cancel Offer</button>
                    ) : (currentAddress.toLowerCase() == owner.toLowerCase() ? (
                        <button onClick={() => acceptOffer(item)} className="mainButton" style={{ fontSize: "80%", padding: "7.5%" }}>Accept Offer</button>
                    ) : (
                        <div />
                    )
                    )}

                </div>
            </div >
        )
    }
    );

    return Mapping;
}


const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{}}>
                <div className="w-full h-10 bg-dark800 rounded-full animate-pulse" />
            </div>
        )
    }
    );

    return Mapping;
}