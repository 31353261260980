import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { callW3Api, getWnsDomain } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { timeToString } from "../../../1.resources/2.js/0.global/0.smallfunctions/time";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";
import WnsLogo from "../../0.global/logo/wnsLogo";
import { colors } from "../../../1.resources/1.css/colors";
import makeBlockie from "ethereum-blockies-base64";
import { zeroAddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import { ethToUsd } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";

const Activity = ({ tokenId }) => {

    const [activity, setActivity] = useState([]);
    const [loading, setLoading] = useState(true);

    async function checkActivity() {
        if (tokenId != "") {
            let contractAddress = window.location.pathname.includes("name") ? Variables().wnsErc721Addr : (window.location.pathname.includes("membership") ? Variables().wnsMembershipAddr : "")
            console.log(tokenId, contractAddress);
            let activityResult = await callW3Api("/database/marketplace/activity/get", { tokenId: tokenId, contractAddress: contractAddress });
            console.log(activityResult);
            if (activityResult != "null") {
                setActivity(activityResult);
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        checkActivity();
    }, [tokenId]);

    let css = {
        p: "text-white whitespace-nowrap overflow-hidden text-left text-lg",
        headingp: "text-dark500 whitespace-nowrap overflow-hidden text-left text-md",
        outerDiv: "flex justify-between py-4 border-b border-b-dark800",
        headingDiv: "flex justify-between pb-2 border-b border-b-dark800"

    }
    return (
        <div className="bg-dark900 mt-10 rounded-2xl md:border-2 border-dark800 px-5 md:px-10 py-8 w-full">
            {/* <div className="p-3 px-4 rounded-t-xl bg-gray-50 flex justify-between shadowDiv">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-chart-line']} className="text-dark500" style={{ fontSize: "90%" }} />
                    <p className="font-semibold text-dark500 ml-2 text-md">Activity</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}
            <p className="font-semibold text-white ml-0 text-xl">Activity</p>
            <p className="text-dark500 ml-0 text-lg mt-2">Recent activity on the blockchain</p>

            <div className="mt-8 w-full overflow-x-scroll" >
                {loading ? (
                    <div>
                        <div className="grid grid-cols-5 pag-10">
                            <GetSkeletons paramsArray={[...Array(1).keys()]} />
                        </div>
                    </div>
                ) : (
                    activity.length != 0 ? (
                        <div className="min-w-[1080px]">
                            <div className={css.headingDiv} >
                                <div style={{ width: "20%" }} className="onlyvertcenter">
                                    <p className={css.headingp}>Type</p>
                                </div>
                                <div style={{ width: "20%" }} className="onlyvertcenter">
                                    <p className={css.headingp}>From</p>
                                </div>
                                <div style={{ width: "20%" }} className="onlyvertcenter">
                                    <p className={css.headingp}>To </p>
                                </div>
                                <div style={{ width: "20%" }} className="onlyvertcenter">
                                    <p className={css.headingp}>Price</p>
                                </div>
                                <div style={{ width: "20%" }} className="onlyvertcenter" >
                                    <p className={css.headingp}>Time</p>
                                </div>
                            </div >
                            <div>
                                {activity.length != 0 ? (
                                    <div>
                                        {activity.map((item, index) => (
                                            <div key={index} >
                                                <GetMapping item={item} css={css} />
                                            </div>
                                        ))}
                                    </div>
                                ) : (null)}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p className="text-dark500">The activity on this domain will appear here once it is minted on the Blockchain.</p>
                        </div>
                    )
                )}
            </div>

        </div >

    );

}

export default Activity;

const GetMapping = ({ item, css }) => {
    const [priceUsd, setPriceUsd] = useState(null);

    async function getPrice() {
        setPriceUsd(await ethToUsd(item.value / 1e18));
    }

    useEffect(() => {
        getPrice();
    }, [])

    return (
        <div className={css.outerDiv}>
            <div style={{ width: "20%" }} className="flex items-center">
                <FontAwesomeIcon icon={['fas', item?.from == "0x0000000000000000000000000000000000000000" ? 'fa-plus' : "fa-right-left"]} className="text-dark500 pr-2" style={{ fontSize: "90%" }} id="" />
                <p className={`${css.p} text-dark500`}>{item?.from == "0x0000000000000000000000000000000000000000" ? "Mint" : "Transfer"}</p>
            </div>
            <div style={{ width: "20%" }} className="onlyvertcenter">
                <div className={css.p}>
                    <GetDomain address={item?.from} />
                </div>
            </div>
            <div style={{ width: "20%" }} className="onlyvertcenter">
                <div className={css.p}>
                    <GetDomain address={item?.to} />
                </div>
            </div>
            <div style={{ width: "20%" }} className="">
                <p className={css.p}>{item?.value == "0" ? "-" : item?.value / 1e18} ETH</p>
                <p className="text-dark500 text-sm mt-0">{priceUsd ? priceUsd : ""}</p>
            </div>
            <div style={{ width: "20%" }} className="onlyvertcenter" >
                <a href={"https://etherscan.io/tx/" + item?.transactionHash} target="_blank" className="text-main2 text-lg">{timeToString((new Date(item?.blockTimestamp)).getTime())}</a>
                <FontAwesomeIcon icon={['fas', "fa-angle-right"]} className="text-main2" style={{ marginLeft: "5%", fontSize: "90%", paddingRight: "10%" }} id="" />
            </div>
        </div >
    );
}


export const GetDomain = ({ address }) => {
    const [value, setValue] = useState("");
    const [isDomain, setIsDomain] = useState(false);

    async function parseDomain() {
        let result = await getWnsDomain(address);
        if (result && result != "null") {
            setIsDomain(true);
            setValue(result);
        }
    }
    useEffect(() => {
        setValue(shortenaddress(address));
        parseDomain()
    }, [])

    return (
        <div>
            {isDomain ? (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <a className="text-main2" target="_blank"
                        href={"https://opensea.io/" + address}>{value}</a>
                </div>
            ) : (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <a className="text-main2" target="_blank" href={"https://opensea.io/" + address}>{value}</a>
                </div>
            )}
        </div>
    )
}



const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{}}>
                <div className="w-full h-10 bg-dark800 rounded-full animate-pulse" />
            </div>
        )
    }
    );

    return Mapping;
}