import React, { useState, useEffect, useContext } from "react";
import Cart from "../../6.cart/cart";
import Manage from "./partials/manage";
import MyDomains from "./partials/mydomains";
/* import Referral from "./partials/referral";
 */import Wishlist from "./partials/wishlist";

const AccountMain = ({ currentAddress, content, setContent }) => {

    async function init() {

    }
    useEffect(() => {
        init();
    }, [])
    return (
        <div style={{ minHeight: window.innerHeight }} className="bg-dark900 md:border-2 border-dark800 md:rounded-2xl">
            {
                content == "My Domains" ? (
                    <MyDomains currentAddress={currentAddress} />
                ) : content == "Manage" ? (
                    <Manage currentAddress={currentAddress} />
                ) : content == "Wishlist" ? (
                    <Wishlist currentAddress={currentAddress} />
                ) /* : content == "Referral Program" ? (
                    <Referral currentAddress={currentAddress} />
                ) */ : (
                        <div />
                    )
            }

        </div >
    );

}

export default AccountMain;
