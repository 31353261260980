import React, { useState, useEffect } from "react";
import { Variables } from "../../1.resources/2.js/0.global/2.contracts/variables";
import MarketplaceItems from "./partials/items/items";
import MarketplaceUpper from "./partials/upper/upper";

const Marketplace = ({ connectModal, setConnectModal }) => {
    const [contractAddress, setContractAddress] = useState("");
    const [floorPrice, setFloorPrice] = useState("");

    useEffect(() => {
        let path = window.location.pathname;
        let array = path.split("/");
        if (array[2] == "domains") {
            setContractAddress(Variables().wnsErc721Addr);
        }
    }, [])
    return (
        <div className="w-full">
            <div className="w-full flex-1 flex justify-center" id="mainpart">
                <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center pb-0">
                    <MarketplaceUpper contractAddress={contractAddress} floorPrice={floorPrice} />
                </div>
            </div>
            <div className="w-full flex-1 flex justify-center bg-black border-t-0 border-dark900" id="mainpart">
                <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center ">
                    <MarketplaceItems setFloorPrice={setFloorPrice} />
                </div>
            </div>
        </div >
    );

}

export default Marketplace;
