import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollBox } from "../../0.global/scrollbox/scrollbox";


function Categories() {

    let items = [
        { "name": "Premium", subheading: "Explore premium domains", "link": "/category/premium", "icon": "fa-star", color: "#773FDD" },
        { "name": "Dictionary", subheading: "A-Z", "link": "/category/dictionary", "icon": "fa-book", color: "#EF4146" },
        { "name": "Numbers", subheading: "360/180", "link": "/category/numbers", "icon": "fa-stopwatch-20", color: "#DC5BDD" },
        { "name": "Names", subheading: "John / Michael", "link": "/category/names", "icon": "fa-users", color: "#F2A840" },
        { "name": "Countries", subheading: "Germany / France", "link": "/category/countries", "icon": "fa-globe", color: "#1EBE82" },
        { "name": "Cities", subheading: "London / Paris", "link": "/category/cities", "icon": "fa-city", color: "#4176ee" }]
    return (
        <div className="px-5 md:px-0 py-4 flex justify-center items-center pt-0 bg-black">
            <div className="w-full">
                <div className="text-start">
                    <p className="text-4xl md:text-4xl font-semibold text-white">Categories</p>
                    <p className="text-xl text-dark500 pt-4">Explore domains by category</p>
                </div>
                <br />
                <div className="grid grid-cols-1 md:grid-cols-2 pt-4 gap-6 lg:grid-cols-3 px-0 pb-10">

                    {items.map((item, index) => (
                        <a key={index} href={item.link} className="">
                            {/* <ScrollBox elements={ */}
                            <div className="pt-16 pb-20 px-10 md:px-10 bg-dark900 hover:bg-dark800 rounded-0 flex flex-col items-center" >
                                <div className={`w-20 h-20 bg-[${item.color}] flex justify-center items-center`} style={{ backgroundColor: item.color, borderRadius: "18px" }}>
                                    <FontAwesomeIcon icon={['fas', item.icon]} style={{ fontSize: "200%" }} className="text-gray-100" />
                                </div>
                                <p className="text-white font-semibold text-3xl pt-10">{item.name}</p>
                                <p className="text-gray-500 pt-4 text-xl">{item.subheading}</p>
                            </div>
                            {/* } id={"hero-categories" + index} /> */}
                        </a>
                    ))}
                </div>
            </div>
        </div >


    );
}

export default Categories;

