import React, { useState, useContext, useEffect } from "react";

const WnsToken = ({ walletConnected, setWalletConnected }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "Home - W3 Name Service"
    }, [])
    return (
        <div className="min-h-screen">
            <div>

            </div>
        </div>
    );

}

export default WnsToken;
