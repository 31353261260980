import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox, Transition } from '@headlessui/react'
import { Variables } from "../../../../../1.resources/2.js/0.global/2.contracts/variables";
import { callW3Api } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { useAccount, useConnect, useSigner } from 'wagmi'
import { ethers } from "ethers";
import { getCloudProvider } from "../../../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { colors } from "../../../../../1.resources/1.css/colors";

const PrimaryName = ({ currentAddress, setPrimaryVisible }) => {
    const { data: signer, isError, isLoading } = useSigner()
    const [selected, setSelected] = useState()
    const [domains, setDomains] = useState([])
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [spinnerp, setSpinnerp] = useState("");
    const [linkVisible, setLinkVisbible] = useState(false);
    const [linkHref, setLinkHref] = useState("");
    const [errorVisible, setErrorVisbible] = useState(false);
    const [errorp, setErrorp] = useState("");
    const [infoVisible, setInfoVisible] = useState(false);
    const [infop, setInfop] = useState("");

    async function init() {
        console.log(currentAddress);
        if (currentAddress != "" && currentAddress != "null") {
            let result = await callW3Api("/database/account/tokens/get/all", { address: currentAddress, contractAddress: Variables().wnsErc721Addr });
            console.log(result);
            setDomains(result);
            setSelected(result[0]);
        }
    }
    useEffect(() => {
        init();
    }, [])

    async function save() {
        console.log(selected.tokenId);
        setSpinnerp("Please wait...")
        setSpinnerVisible(true);
        const mainContract = new ethers.Contract(Variables().wnsResolverAddr, Variables().wnsResolverAbi, signer);
        setSpinnerp("Confirm the transaction in your wallet...")
        try {
            const tx = await mainContract.setPrimaryName(currentAddress, selected.tokenId);
            setSpinnerVisible(false);
            setLinkVisbible(true);
            setLinkHref("https://etherscan.io/tx/" + tx.hash);
            getCloudProvider("eth", process.env.REACT_APP_NETWORK, "full").waitForTransaction(tx.hash, 1, 300000).then(async (receipt) => {
                window.location.reload();
            });
        } catch (e) {
            setSpinnerVisible(false);
            setErrorp("Something went wrong.")
            setErrorVisbible(true);
        }
        //window.location = window.location.pathname.replace(getChainPath(), "/" + link);
    }
    return (
        <div>
            {selected != null ? (
                <div>
                    <p className="text-sm font-bold ml-1 mb-2 text-white">Set Primary Name</p>
                    <div className="flex items-center gap-4 font-semibold">
                        <div className="w-[200px]">
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-dark800 px-4 py-2 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                        <div className="flex">
                                            <span className="block truncate text-white">{selected.domain + ".web3"}</span>
                                        </div>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-white" style={{ fontSize: "100%" }} aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options style={{ zIndex: "29109090456789099", boxShadow: "0 0 0 2000px rgba(0, 0, 0, 0.25)" }} className="absolute mt-1 max-h-80 w-full overflow-auto rounded-md bg-dark800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {domains.map((item, chainIdx) => (
                                                <Listbox.Option
                                                    key={chainIdx}
                                                    className={`py-3 px-4 text-black border-b border-b-dark700 hover:bg-dark600 cursor-pointer`}
                                                    value={item}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span className={`block truncate font-semibold text-white`}>{item.domain + ".web3"}</span>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="flex items-center gap-2">
                            <button onClick={() => save()} className="bg-blue-500 text-white text-sm" style={{ padding: "6px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "12.5px" }}>Save</button>
                            <button onClick={() => setPrimaryVisible(false)} className="text-blue-500  text-sm" style={{ padding: "6px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "12.5px" }}>Cancel</button>
                        </div>
                    </div>

                    <div style={{ display: spinnerVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                        <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: colors.main, fontSize: "70%" }} spin />
                        <p style={{ fontSize: "80%", fontWeight: "bold", color: colors.main, marginLeft: "1%", whiteSpace: "nowrap" }}>{spinnerp}</p>
                    </div>
                    <div style={{ display: errorVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                        <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "70%" }} />
                        <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%", whiteSpace: "nowrap" }}>{errorp}</p>
                    </div>
                    <div style={{ display: infoVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex  items-center">
                        <FontAwesomeIcon icon={['fas', 'fa-check']} style={{ color: colors.main, fontSize: "70%" }} />
                        <p style={{ fontSize: "80%", fontWeight: "bold", color: colors.main, marginLeft: "1%", whiteSpace: "nowrap" }}>{infop}</p>
                    </div>
                    <div style={{ display: linkVisible ? "flex" : "none", marginBottom: "1%", marginTop: "4%" }} className="flex items-center">
                        <a className="bluelink" href={linkHref} target="_blank" style={{ fontSize: "80%", fontWeight: "bold", color: colors.main, whiteSpace: "nowrap" }}>View transaction on Etherscan</a>
                        <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} style={{ color: colors.main, fontSize: "70%", marginLeft: "1%" }} />
                    </div>
                </div>
            ) : (
                <div />

            )}
        </div>

    );

}


export default PrimaryName;



