import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ConnectWallet } from "../wallet/connectWallet";
import { RiShoppingCartLine } from "react-icons/ri";

const HeaderRight = ({ connectModal, setConnectModal }) => {
    /* const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);
    const [address, setAddress] = useState("")
    const [walletConnected, setWalletConnected] = useState(false)


    async function checkAddress() {
        let currentAddress = await getcurrentaddress();
        if (currentAddress == "null") {
            setWalletConnected(false);
        } else {
            setWalletConnected(true);
            setAddress(shortenaddress(currentAddress));
        }
    }
    useEffect(() => {
        checkAddress()
    }, [])

    useEffect(() => {
        if (localStorage.getItem("darkmode") == null) {
            localStorage.setItem("darkmode", "false");
        } else {
            if (localStorage.getItem("darkmode") == "true") {
                toggleTheme();
            }
        }
    }, [])

    function toggleDarkMode() {
        if (isDark == true) {
            localStorage.setItem("darkmode", "false");
            toggleTheme();
        } else {
            localStorage.setItem("darkmode", "true");
            toggleTheme();
        }
    } */
    return (
        <div className="flex gap-x-6 items-center">
            <a href="/cart" className="cursor-pointer w-12 h-12 flex justify-center items-center bg-dark900 hover:bg-dark800 rounded-full">
                <RiShoppingCartLine className="text-dark500 text-xl" />
            </a>
            <ConnectWallet />
        </div>
    );

}


export default HeaderRight;
