import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const punycode = require('punycode/');


const MobileSearch = ({ }) => {
    const [inputValue, setInputValue] = useState("");


    async function setInput(value) {
        if (value.length < 15 && !value.includes(".")) {
            setInputValue(value.replace(" ", "").toLowerCase());
        }
    }

    return (
        <div style={{ zIndex: "1000023409324234234", minHeight: window.innerHeight, background: "#000", padding: "5%" }}>
            <input type="text" value={inputValue} onChange={(e) => setInput(e.target.value)} style={{
                width: "100%",
                border: "0", outline: "0",
                fontWeight: "semibold", color: "#fff", borderRadius: "12.5px"
            }} className={" bg-dark900 p-3"} placeholder="Type to search for a domain..." />

            <div style={{ display: inputValue == "" ? "none" : "block" }}>
                <div style={{ marginTop: "10%" }}>
                    <div className="border-b border-dark900">
                        <p style={{ fontSize: "80%", fontWeight: "semibold", color: "#fff" }}>Domains</p>
                    </div>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <a href={"/name/" + punycode.toASCII(inputValue)} style={{ borderRadius: "12.5px" }} className={"bg-dark900 p-5 onlyvertcenter"}>
                        <p style={{ fontSize: "100%", fontWeight: "semibold", color: "#fff" }}>{inputValue}</p>
                        <p style={{ fontSize: "100%", fontWeight: "semibold", color: "#fff" }}>.web3</p>
                    </a>
                </div>
            </div>
        </div >
    )

}

export default MobileSearch;
