import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { normalize, toASCII, toUnicode } from "../../1.resources/2.js/0.global/0.smallfunctions/global";
import CloudContracts from "../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";
import Activity from "./partials/activity";
import Coins from "./partials/coins";
import Details from "./partials/details";
import Image from "./partials/image";
import TokenModal from "./partials/modal/tokenModal";
import Offers from "./partials/offers";
import Price from "./partials/price";
import Titles from "./partials/titles";
import W3 from "./partials/w3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingImg from "../../1.resources/3.files/images/loading.gif";
import { colors } from "../../1.resources/1.css/colors";
const punycode = require('punycode/');

let validTokens = ["xn--fn8h24640cjagc5b3h", "xn--1ug6046pzcc", "xn--1ugz855pdld"]
const Token = ({ connectModal, setConnectModal }) => {
    let { address } = useAccount();
    const [domain, setDomain] = useState("")
    const [registered, setRegistered] = useState(false)
    const [tokenLoading, setTokenLoading] = useState(true)
    const [invalid, setInvalid] = useState(false)
    const [tokenId, setTokenId] = useState("")
    const [owner, setOwner] = useState("");
    const [currentAddress, setCurrentAddress] = useState("");
    const [tokenModal, setTokenModal] = useState(false);
    const [tokenParam, setTokenParam] = useState("");
    const [tokenParam2, setTokenParam2] = useState("");

    async function checkDomain() {
        let urlArray = window.location.pathname.split("/");
        try {
            if (!validTokens.includes(urlArray[2].toLowerCase())) {
                let normalized = toASCII(normalize(punycode.toUnicode(urlArray[2].toLowerCase())));
                if (urlArray[2].toLowerCase() != normalized) {
                    window.location = "/name/" + normalized;
                }
            }
            console.log(toUnicode(urlArray[2].toLowerCase()).length)
            if (window.location.pathname.includes("name")) {
                setDomain(urlArray[2].toLowerCase());
                document.getElementById("title").innerHTML = punycode.toUnicode(urlArray[2].toLowerCase()) + ".web3 - W3 Name Service"
                if (punycode.toUnicode(urlArray[2]).length > 15 || punycode.toUnicode(urlArray[2]).length < 3 || urlArray[2].includes(".")) {
                    setInvalid(true);
                } else {
                    console.time('asdasdasdasd')
                    let token = await callW3Api("/database/get/resolveName", { name: urlArray[2].toLowerCase() });
                    //let token = (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.resolveNameToTokenId(urlArray[2], "")).toString();
                    console.log(token)
                    console.timeEnd('asdasdasdasd')
                    console.time('summBrute')
                    CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsErc721Contract.ownerOf(token).then((res) => {
                        setRegistered(true);
                        setTokenId(token);
                        setCurrentAddress(address);
                        setOwner(res);
                        setTokenLoading(false);
                    }).catch((e) => {
                        setRegistered(false);
                        setCurrentAddress(address);
                        setTokenLoading(false);
                    })
                    // try {


                    // } catch (e) {
                    //     setRegistered(false);
                    //     setCurrentAddress(address);
                    // }
                    console.timeEnd('summBrute')
                }
            } else {
                setRegistered(true);
                setTokenId(urlArray[2]);
                setCurrentAddress(address);
                try {
                    let param = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsMembershipContract.ownerOf(urlArray[2]);
                    setOwner(param);
                } catch (e) {
                    setInvalid(true);
                }
            }
        } catch (e) {
            setInvalid(true);
        }

    }
    useEffect(() => {
        checkDomain();
    }, [])

    return (
        <div style={{ paddingTop: "2.5%", paddingBottom: "5%" }} className="md:bg-black w-full">
            {window.innerWidth < 800 ? (
                <div style={{ display: "block", paddingLeft: "4%", paddingRight: "4%" }}>
                    <div style={{ width: "100%", paddingLeft: "2%", paddingRight: "2%" }} className="">
                        <div>
                            <Titles invalid={invalid} domain={domain} registered={registered} tokenId={tokenId} owner={owner} />
                            <div className="mt-5">
                                <Image domain={domain} registered={registered} tokenId={tokenId} />
                            </div>


                            {invalid ? (
                                <div></div>
                            ) : (
                                <div>
                                    <Price domain={domain} registered={registered} tokenId={tokenId} owner={owner} currentAddress={currentAddress} setTokenModal={setTokenModal} setTokenParam={setTokenParam} connectModal={connectModal} setConnectModal={setConnectModal} tokenLoading={tokenLoading} />
                                    <Details domain={domain} registered={registered} tokenId={tokenId} />
                                    {registered ? (
                                        <div>
                                            {window.location.pathname.includes("membership") ?
                                                (<div />) : (
                                                    <Coins tokenId={tokenId} owner={owner} />
                                                )}
                                            <Offers domain={domain} registered={registered} tokenId={tokenId} owner={owner} currentAddress={currentAddress} setTokenParam={setTokenParam} setTokenModal={setTokenModal} setTokenParam2={setTokenParam2} />
                                        </div>
                                    ) : (
                                        <div>
                                        </div>
                                    )
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full flex-1 flex justify-center" id="mainpart">
                    <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                        <div className="w-4/12">
                            <div className="pr-10">
                                <Image domain={domain} registered={registered} tokenId={tokenId} />
                                <Details domain={domain} registered={registered} tokenId={tokenId} />
                            </div>
                        </div>

                        <div className="w-8/12 rounded-2xl px-10 py-10 border-2 border-dark800 bg-dark900">
                            <div>
                                <div className="flex gap-x-10">
                                    {/* <Image domain={domain} registered={registered} tokenId={tokenId} /> */}
                                    <Titles invalid={invalid} domain={domain} registered={registered} tokenId={tokenId} owner={owner} tokenLoading={tokenLoading} />
                                </div>
                                {invalid ? (
                                    <div></div>
                                ) : (
                                    <div>
                                        <Price domain={domain} registered={registered} tokenId={tokenId} owner={owner} currentAddress={currentAddress} setTokenModal={setTokenModal} setTokenParam={setTokenParam} connectModal={connectModal} setConnectModal={setConnectModal} tokenLoading={tokenLoading} />
                                        {registered ? (
                                            <div>
                                                {window.location.pathname.includes("membership") ?
                                                    (<div />) : (
                                                        <Coins tokenId={tokenId} owner={owner} />
                                                    )}
                                                <Offers domain={domain} registered={registered} tokenId={tokenId} owner={owner} currentAddress={currentAddress} setTokenParam={setTokenParam} setTokenModal={setTokenModal} setTokenParam2={setTokenParam2} />
                                            </div>
                                        ) : (
                                            !tokenLoading && (
                                                <div></div>
                                            )
                                        )
                                        }

                                    </div>

                                )}
                            </div>


                        </div>
                    </div>
                </div>
            )
            }

            <div className="w-full flex-1 flex justify-center" id="mainpart">
                <div className="w-full 2xl:w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                    <Activity tokenId={tokenId} />
                </div>
            </div>
            <TokenModal owner={owner} tokenModal={tokenModal} setTokenModal={setTokenModal} tokenParam={tokenParam} setTokenParam={setTokenParam} tokenId={tokenId} currentAddress={currentAddress} tokenParam2={tokenParam2} />
        </div >
    );

}

export default Token;
