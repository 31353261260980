import React, { useState, useEffect, useContext } from "react";
import "./1.resources/2.js/0.global/0.smallfunctions/imports_css";
import Main from "./main";
import '@rainbow-me/rainbowkit/styles.css';
import { darkTheme, getDefaultWallets, RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { AuthProvider } from "./3.components/1.wrappers/1.auth";
import merge from 'lodash.merge';

const App = ({ }) => {
  const { chains, provider } = configureChains(
    [mainnet],
    [jsonRpcProvider({
      rpc: (chain) => ({
        http: "https://ethereum-mainnet.core.chainstack.com/b56d8d9b8d522bc39b72133a6876101c",
      }),
    })]
  );

  const { connectors } = getDefaultWallets({
    appName: 'WNS (Web3 Name Service)',
    chains
  });

  const wagmiClient = createClient({
    autoConnect: localStorage.getItem("walletConnected") == null ? false : true,
    connectors,
    provider
  })

  const myDarkTheme = merge(darkTheme({
    fontStack: "system",
    borderRadius: "large"
  }), {
    colors: {
      accentColor: "#3B81F6",
      modalBackground: "rgb(24 24 27)",
    }
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={myDarkTheme} >
        <AuthProvider>
          <Main />
        </AuthProvider>
      </RainbowKitProvider>
    </WagmiConfig>

  );

}

export default App;

