import { callW3Api } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";


export async function uploadImage(src, editJson, setEditJson, setHash, setHashLoading) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    let size = 600;

    var image = new Image();

    image.src = src;
    image.onload = async function (e) {
        canvas.width = size; // target width
        canvas.height = image.height * (size / image.width); // target height
        ctx.drawImage(image,
            0, 0, image.width, image.height,
            0, 0, canvas.width, canvas.height
        );
        var resampledImage = new Image();
        resampledImage.src = canvas.toDataURL();
        let url = canvas.toDataURL();
        //let url2 = canvas.toBlob();
        console.log(canvas.toDataURL());
        try {
            let hash = await callW3Api("/database/ipfs/upload/image", { content: url });
            console.log(hash);
            let json = editJson[0];
            json["avatar"] = "ipfs-" + hash;
            setEditJson([json]);
            setHash(hash);
            console.log(hash);
        } catch (e) {
            console.log(e);
            setHash("Error: File too large...");
        }
        setHashLoading(false);
    };
};

