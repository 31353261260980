import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MembershipImg from "../../../../../../1.resources/3.files/images/membership.gif";
import { calculateZeroes } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { colors } from "../../../../../../1.resources/1.css/colors";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { FaRegCheckCircle, FaCheckCircle } from "react-icons/fa";
const punycode = require('punycode/');
export const ItemsBox = ({ item, membership, select, selected, toggleSelected, index }) => {
    const [reload, setReload] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);

    useEffect(() => {
        setReload(!reload);
    }, [selected])
    return (
        <div>
            <div style={{ borderRadius: "18px" }} className={"hover:-translate-y-2 bg-dark900 border-2 border-dark800"}>
                <a href={
                    membership ?
                        "/membership/" + item.tokenId :
                        "/name/" + item.name.substring(0, item.name.indexOf("."))
                }>
                    <div style={{ display: imgLoaded ? "none" : "block", borderRadius: "18px" }} className={"p-2"}>
                        <SkeletonTheme baseColor={colors.gray100} highlightColor={colors.gray200} >
                            <Skeleton count={1} style={{
                                width: "100%",
                                paddingTop: "100%",
                                borderRadius: "18px"
                            }} />
                        </SkeletonTheme>
                    </div>
                    <img src={
                        membership ? MembershipImg :
                            item.imageHash == null || item.imageHash == "null" || item.imageHash == "" ? process.env.REACT_APP_API_URL + `/metadata/generateimage=${item.name}?400` :
                                process.env.REACT_APP_API_URL + `/metadata/generateimageavatar=${item.name}&${item.imageHash}?400`}
                        style={{ borderRadius: "20px", display: imgLoaded ? "block" : "none" }}
                        onLoad={() => setImgLoaded(true)} className="p-2 " />




                    <div className="px-4 pb-2">
                        <div className="flex items-center justify-between mt-2">

                            {/* <p className="text-dark500  text-md">
                                {"Name"}</p> */}

                            <p className="font-semibold text-white text-md truncate text-end">{punycode.toUnicode(item.name)}</p>


                        </div>
                        <div className="flex items-center justify-between mt-2">

                            {/* <p className="text-dark500 truncate text-md">
                                {"Price"}</p> */}

                            <p className="font-semibold text-white  text-lg text-end">{item?.cost != 0 && item?.cost != "-" && isNaN(item?.cost) != true ? calculateZeroes((item.cost / 1e18).toFixed(3)) + " ETH" : "-"} </p>


                        </div>
                    </div>
                </a>
                <div onClick={() => toggleSelected(index)} style={{ cursor: "pointer", borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px", padding: "5%", paddingLeft: "10%", paddingRight: "10%" }} className="onlyvertcenter border-t-2 border-t-dark800 bg-dark900">
                    <div style={{ opacity: select ? "1" : "0" }} className="py-2">
                        {/* {selected.includes(index) ?
                            <FaCheckCircle style={{ color: colors.main, fontSize: "110%" }} />
                            :
                            <FaCheckCircle style={{ color: colors.gray400, fontSize: "110%" }} />} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemsBox;


