import React, { useState, useContext, useEffect } from "react";
import "./styles.css";

import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const punycode = require('punycode/');

Modal.setAppElement("#root");

export default function ManageModal({ currentDomain, setCurrentDomain, domains, currentAddress, modalVisible, setModalVisible }) {


    function toggleModal() {
        setModalVisible(!modalVisible);
    }

    useEffect(() => {
        //init();
    }, [currentAddress])

    return (
        <div style={{ display: "block" }}>

            <Modal
                isOpen={modalVisible}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="walletModal"
                overlayClassName="myoverlay"
            >
                <div style={{ height: window.innerHeight * 0.7 }} className={"shadow border border-dark800 bg-dark900"}>
                    <div className="py-10">
                        <div className="px-10 pb-4 ">
                            <p className="font-bold text-3xl text-white">Select domain</p>
                            <p className="text-dark500 mt-2">Confirm the domain to manage</p>
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px", marginTop: "2%" }} className="bg-dark900 px-10 py-10 border-t-2 border-dark800">
                            <Domains setModalVisible={setModalVisible} paramsArray={domains} setCurrentDomain={setCurrentDomain} />
                        </div>
                    </div>
                </div>

            </Modal >
        </div >
    );
}



export const Domains = ({ paramsArray, setCurrentDomain, setModalVisible }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div onClick={() => { setCurrentDomain(index); setModalVisible(false) }} key={index} style={{ justifyContent: "space-between", marginTop: index == 0 ? "2%" : "2%", padding: "5%", paddingLeft: "10%", paddingRight: "10%", borderRadius: "12.5px" }} className="border-2 border-dark700 onlyvertcenter whitehover bg-dark800 hover:bg-dark700 cursor-pointer">
                <div>
                    <p className="text-sm text-dark500 font-semibold" >#{item.tokenId}</p>
                    <p className="font-semibold text-xl text-white" >{punycode.toUnicode(item?.domain) + item.extension}</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-right']} style={{ fontSize: "100%" }} className="text-dark500" />
                </div>
            </div>
        )
    }
    );

    return Mapping;
}

