import React, { useState, useEffect, useContext } from "react";
import { Variables } from "../../../../../1.resources/2.js/0.global/2.contracts/variables";
import { callW3Api, getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import InfiniteScroll from 'react-infinite-scroll-component';
import { timeToString } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudContracts from "../../../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import MembershipImg from "../../../../../1.resources/3.files/images/membership.png";
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import WnsLogo from "../../../../0.global/logo/wnsLogo";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { colors } from "../../../../../1.resources/1.css/colors";
import { zeroAddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import makeBlockie from "ethereum-blockies-base64";
import { ethToUsd } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
const punycode = require('punycode/');

const MarketplaceActivity = ({ }) => {
    const [market, setMarket] = useState("");
    const [items, setItems] = useState([{}]);
    const [noMore, setNoMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [cursor, setCursor] = useState('');

    async function fetchNext() {
        setLoading(true);
        let contractAddress = window.location.pathname.includes("domain") ? Variables().wnsErc721Addr : Variables().wnsMembershipAddr;
        let activities = await callW3Api("/database/marketplace/activity/getAll", { contractAddress: contractAddress, cursor: cursor });
        console.log(activities);
        setCursor(activities.cursor);
        setItems([...items, ...activities.result]);
        setLoading(false);
    }

    useEffect(() => {
        fetchNext();
    }, [])

    let css = {
        p: "font-semibold text-md text-white",
        sub: "text-dark500 text-md font-semibold",
        headingp: "text-dark500 text-md font-semibold"
    }
    return (
        <div style={{}} className="pb-20 pt-10 w-full overflow-x-scroll">
            <div className="rounded-2xl min-w-[1080px]">
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchNext}
                    hasMore={noMore}>

                    {items.map((item, index) => {
                        return (
                            <div key={index} >
                                <div className={!item.from ? "onlyvertcenter py-6 px-6 bg-black border-b-2 border-b-dark900 rounded-t-2xl" : `tableRow onlyvertcenter py-4 px-6 hover:shadow-lg bg-black hover:bg-dark900 border-b-2 border-b-dark900`} key={index}
                                    style={{ display: "flex" }}>
                                    <div onClick={() => window.location = "/name/" + item?.domain} style={{ width: "15%" }} className="onlyvertcenter cursor-pointer">
                                        <FontAwesomeIcon icon={['fas',
                                            item.from == "0x0000000000000000000000000000000000000000" ? "fa-plus" :
                                                item.calledAddress == "0x604bf285cccf03c4aab093d556b04caaec2f7afb" ? "fa-bag-shopping" :
                                                    item.calledAddress == "0x36C72892fcc72b52FA3b82eD3BB2A467D9079B9A" ? "fa-bag-shopping" :
                                                        "fa-right-left"
                                        ]} className="mr-4 text-gray-500" style={{ display: !item.from ? "none" : "block" }} id="arrowicon" />

                                        {!item.from ? (
                                            <p className={css.headingp}>Type</p>
                                        ) : (
                                            <p className={css.sub}>{
                                                !item.from ? "Type" :
                                                    item.from == "0x0000000000000000000000000000000000000000" ? "Mint" :
                                                        item.calledAddress == "0x604bf285cccf03c4aab093d556b04caaec2f7afb" ? "Sale" :
                                                            item.calledAddress == "0x36C72892fcc72b52FA3b82eD3BB2A467D9079B9A" ? "Sale" :
                                                                "Transfer"
                                            }</p>
                                        )}
                                    </div>
                                    <div onClick={() => window.location = "/name/" + item?.domain} style={{ width: "25%" }} className="onlyvertcenter  cursor-pointer">
                                        {!item.from ? (
                                            <p className={css.headingp}>Item</p>
                                        ) : (
                                            <div className="onlyvertcenter">
                                                <img src={window.location.pathname.includes("membership") ? MembershipImg : process.env.REACT_APP_API_URL + "/metadata/generateimage=" + item?.domain + ".web3?40"} style={{ borderRadius: "6px", width: "40px" }} />
                                                <p className={css.p} style={{ paddingLeft: "15px" }}>{
                                                    window.location.pathname.includes("membership") ? `Early Member #${item?.tokenId}` : punycode.toUnicode(item?.domain) + ".web3"}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={() => window.location = "/name/" + item?.domain} style={{ width: "20%", display: "flex" }} className="cursor-pointer">
                                        {!item.from ? (
                                            <p className={css.headingp}>Price</p>
                                        ) : (
                                            <div>
                                                <p className={css.p}>{item.value == 0 ? "-" : item.value / 1e18 + " ETH"}</p>
                                                {item.value == 0 ? "-" : <UsdCost price={item.value / 1e18} />}
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={() => "https://opensea.io/" + item?.from} style={{ width: "22.5%", fontSize: "bold" }}>
                                        {!item.from ? (
                                            <p className={css.headingp}>From</p>
                                        ) : (
                                            <GetDomain address={item?.from} />
                                        )}
                                    </div>
                                    <div onClick={() => "https://opensea.io/" + item?.to} style={{ width: "22.5%", fontSize: "bold" }}>
                                        {!item.from ? (
                                            <p className={css.headingp}>To</p>
                                        ) : (
                                            <GetDomain address={item?.to} />
                                        )}
                                    </div>
                                    <a style={{ width: "15%" }} target="_blank" href={"https://etherscan.io/tx/" + item.transactionHash}>
                                        {!item.from ? (
                                            <p className={css.headingp}>Time</p>
                                        ) : (
                                            <div className="onlyvertcenter">
                                                <p className="font-semibold text-main text-md" >{timeToString((new Date(item.blockTimestamp)).getTime())}</p>
                                                <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} className="text-main" style={{ fontSize: "70%", marginLeft: "2.5%" }} id="arrowicon" />
                                            </div>
                                        )}
                                    </a>

                                </div>
                            </div>
                        )
                    })}
                </InfiniteScroll >

                <div style={{ display: loading ? "block" : "none" }}>
                    <GetSkeletons paramsArray={[...Array(2).keys()]} />
                </div>
            </div>
        </div >
    );

}

export default MarketplaceActivity;

export const UsdCost = ({ price }) => {
    const [value, setValue] = useState("");


    async function init() {
        setValue(await ethToUsd(price));
    }
    useEffect(() => {
        init()
    }, [])

    return (
        <div>
            <p className="text-gray-500 text-sm font-semibold">{value}</p>
        </div>
    )
}

export const GetDomain = ({ address }) => {
    const [value, setValue] = useState("");
    const [isDomain, setIsDomain] = useState(false);

    async function parseDomain() {
        if (address != zeroAddress) {
            let result = await getWnsDomain(address);
            if (result != "null") {
                setValue(result);
                setIsDomain(true);
            }
        }
    }
    useEffect(() => {
        setValue(shortenaddress(address));
        parseDomain()
    }, [])

    return (
        <div>
            {isDomain ? (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <a className="font-semibold text-main text-md" target="_blank" href={"https://opensea.io/" + address}>{value}</a>
                </div>
            ) : (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <a className="font-semibold text-main text-md" target="_blank" href={"https://opensea.io/" + address}>
                        {address != zeroAddress ? value : "-"}
                    </a>
                </div>
            )}
        </div>
    )
}

const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{ borderRadius: "0px" }} className={"py-10 bg-dark900 animate-pulse mb-2"}>

            </div>
        )
    }
    );
    return Mapping;
}