import React, { useState, useEffect, useContext } from "react";
import MarketplaceActivity from "./partials/activity";
import { MarketplaceListings } from "./partials/items";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from "../../../../1.resources/1.css/colors";

const MarketplaceItems = ({ connectModal, setConnectModal, setFloorPrice }) => {
    const [tabSelected, setTabSelected] = useState("");

    useEffect(() => {
        setTabSelected("items");
    }, [])

    return (
        <div className="w-full">
            <div className="px-0 md:px-0 flex items-center gap-x-10" style={{ paddingTop: "2.5%" }}>
                <button onClick={() => setTabSelected("items")} className={`font-semibold flex items-center gap-x-3 ${tabSelected == "items" ? ("bg-dark900 rounded-full px-4 py-2") : ("text-dark500")}`}>
                    <FontAwesomeIcon icon={['fas', 'fa-box']} className={`${tabSelected == "items" ? "text-dark500" : "text-dark500"}`} />
                    <p className={`text-md ${tabSelected == "items" ? "text-dark500" : "text-dark500"}`}>Items</p>
                </button>
                <button onClick={() => setTabSelected("activity")} className={`font-semibold flex items-center gap-x-3 ${tabSelected == "activity" ? ("bg-dark900 rounded-full px-4 py-2") : ("text-dark500")}`}>
                    <FontAwesomeIcon icon={['fas', 'fa-chart-line']} className={`${tabSelected == "activity" ? "text-dark500" : "text-dark500"}`} />
                    <p className={`text-md ${tabSelected == "activity" ? "text-dark500" : "text-dark500"}`}>Activity</p></button>
            </div >

            <div className="bg-black px-0 md:px-0 pt-0 w-full">
                <div style={{ display: tabSelected == "activity" ? "block" : "none" }}>
                    <MarketplaceActivity />
                </div>

                <div style={{ display: tabSelected == "items" ? "block" : "none" }}>
                    <MarketplaceListings setFloorPrice={setFloorPrice} />
                </div>
            </div>
        </div>
    );

}

export default MarketplaceItems;
