import React, { useState, useContext, useEffect } from "react";
/* import Header from "../0.global/header/header";
import Hero from "./partials/hero";
import Wns from "./partials/wns";
import Footer from "./partials/footer";
import Wallet from "./partials/wallet";
import NFT from "./partials/nft";
import Payments from "./partials/payments";
import Websites from "./partials/websites";
import Email from "./partials/email";
import Extension from "./partials/extension";
import ReferralMain from "./partials/referral";
import Recent from "./partials/recent";
*/
import Categories from "./partials/categories";
import Extension from "./partials/extension";
import Hero from "./partials/hero";
import Questions from "./partials/questions";
import UseCases from "./partials/useCases";
import W3 from "./partials/w3";
import Marketplace from "./partials/marketplace";
const Home = ({ walletConnected, setWalletConnected }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "Home - W3 Name Service"
    }, [])
    return (
        <div>
            <div>
                {/* <Hero walletConnected={walletConnected} setWalletConnected={setWalletConnected} /> */}
                <Hero />
                <div className="w-full flex justify-center pt-0 bg-black z-50" id="mainpart">
                    <div className="w-full 2xl:max-w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0">
                        <Marketplace />
                        <Categories />
                        {/* <UseCases /> */}
                        <Questions />
                    </div>
                </div>
                {/* <W3 /> */}
                {/* <Wallet /> */}

                {/* <div style={{ marginTop: window.innerWidth < 800 ? "15%" : "0%", marginBottom: "2.5%", textAlign: window.innerWidth < 800 ? "left" : "center", marginLeft: window.innerWidth < 800 ? "10%" : "0%", marginRight: window.innerWidth < 800 ? "10%" : "0%" }}>
                    <p style={{ fontSize: "200%", fontWeight: "bold", color: theme.text }}>Use Cases</p>
                    <p style={{ marginTop: window.innerWidth < 800 ? "2.5%" : "0%", color: theme.subtext }}>Some implementations of Web3 domains</p>

                </div> */}
                {/* <Payments />
                <Websites />
                <Email />
                <Extension />
 */}
            </div>
        </div>
    );

}

export default Home;
