import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Variables } from "../../../1.resources/2.js/0.global/2.contracts/variables";
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { ImArrowUpRight2 } from "react-icons/im"

const Details = ({ domain, registered, tokenId }) => {

    useEffect(() => {
        console.log(domain);
    });

    let css = {
        div: "rounded-xl py-2 w-full",
        req: "text-dark500 text-sm mb-1",
        res: "text-white text-lg"
    }

    return (
        <div className="bg-dark900 mt-8 md:rounded-2xl md:border-2 md:border-dark800 px-5 md:px-8 py-8">
            {/* <div className="pt-4 px-3 rounded-t-xl flex justify-between">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-table-list']} className="text-dark500" style={{ fontSize: "90%" }} />
                    
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}

            <p className="font-semibold text-white text-xl md:text-lg">{registered ? "Details" : "Guidelines"}</p>
            <p className="text-dark500 text-lg md:text-md mt-1">{registered ? "Blockchain information" : "Some things to keep in mind"}</p>
            {
                registered ? (
                    <div>
                        <div className="grid grid-cols-2  gap-3 rounded-b-xl mt-4 pt-4 border-t-2 border-t-dark800" >

                            <div className={css.div}>
                                <p className={css.req}>Token ID</p>
                                <p className={css.res}>#{tokenId}</p>
                            </div >
                            <div className={css.div}>
                                <p className={css.req}>Standard</p>
                                <p className={css.res}>Erc 721</p>
                            </div >
                            <div className={css.div}>
                                <p className={css.req}>Blockchain</p>
                                <p className={css.res}>Ethereum</p>
                            </div >
                            {/* <div className={css.div} >
                            <p className={css.req}>Contract Address</p>
                            <a className={css.res}>
                                {window.location.pathname.includes("name") ? shortenaddress(Variables().wnsErc721Addr) : shortenaddress(Variables().wnsMembershipAddr)}
                            </a>
                        </div > */}
                        </div >
                        <a href={"https://opensea.io/assets/ethereum/0xba9bbea08241845013b40a061e4a77c9345e4562/" + tokenId} target={"_blank"} className="flex items-center gap-x-2 mt-4 pt-4 border-t-2 border-dark800">
                            <p className="text-main">View on OpenSea</p>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-main" style={{ fontSize: "90%" }} />
                        </a>
                    </div>
                ) : (
                    <div className="rounded-b-xl">
                        <ul className="mt-4 pt-4 border-t-2 border-dark800">
                            <li className="text-dark500 mb-6 flex items-center gap-x-4">
                                <FontAwesomeIcon icon={['fas', 'fa-circle']} className="text-dark500" style={{ fontSize: "50%" }} />
                                <p>
                                    All domain registrations are a one-time payment. There are no renewal fees.
                                </p>
                            </li>
                            <li className="text-dark500 mb-6 flex items-center gap-x-4">
                                <FontAwesomeIcon icon={['fas', 'fa-circle']} className="text-dark500" style={{ fontSize: "50%" }} />
                                <p>
                                    The domain will appear in your wallet after your transaction goes through on the Blockchain.
                                </p>
                            </li>
                        </ul>
                    </div>
                )}

        </div >
    );

}

export default Details;
