import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { coinsJson } from "../../../1.resources/2.js/0.global/0.smallfunctions/coins";
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";


const Coins = ({ tokenId, owner }) => {

    const [coins, setCoins] = useState([]);
    const [loading, setLoading] = useState(true);

    async function checkCoins() {
        if (tokenId != "" && owner != "") {
            let keys = Object.keys(coinsJson);

            let txtPromise = []
            for (let i = 0; i < keys.length; i++) {
                txtPromise.push(CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.getTxtRecords(tokenId, keys[i]));
            }
            console.time("await");
            let txts = await Promise.all(txtPromise);
            console.timeEnd("await");
            console.log(txts);

            if (txts[0] == "") {
                txts[0] = owner;
            }

            setCoins(txts);
            setLoading(false);
        }
    }

    useEffect(() => {
        checkCoins();
    }, [tokenId, owner]);

    return (
        <div className="mt-8 pt-16 border-t-2 border-dark800">
            {/* <div className="p-3 px-4 rounded-t-xl bg-gray-50 flex justify-between shadowDiv">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-compass']} className="text-dark500" style={{ fontSize: "90%" }} />
                    
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-dark500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}

            <p className="font-semibold text-white ml-0 text-xl">Mappings</p>
            <div className="rounded-b-xl mt-1" >
                <p className="text-dark500 text-lg">This domain is set to resolve to the following addresses</p>
                {loading ? (<div>
                    <div style={{ display: "grid", gridTemplateColumns: window.innerWidth < 800 ? "repeat(2,1fr)" : "repeat(4, 1fr)", gap: "20px", marginTop: "2.5%" }}>
                        <GetSkeletons paramsArray={[...Array(7).keys()]} />
                    </div>
                </div>) : (
                    <div style={{ display: "grid", gridTemplateColumns: window.innerWidth < 800 ? "repeat(2,1fr)" : "repeat(4, 1fr)", gap: "20px", marginTop: "2.5%" }} >
                        <GetMapping paramsArray={coins} />
                    </div>
                )}
            </div>

        </div >
    );

}

export default Coins;



const GetMapping = ({ paramsArray }) => {


    const Mapping = paramsArray.map((item, index) => {

        return (
            <div key={index} className="flex items-center">
                <div style={{ width: "100%", gap: "7.5px" }} className={"rounded-2xl py-1 bg-dark900 border-2 border-dark800 flex justify-center items-center"}>
                    <img src={coinsJson[Object.keys(coinsJson)[index]]} style={{ width: "30px", height: "30px", borderRadius: "50px" }} />
                    <p className="text-md text-white">{item != "" ? shortenaddress(item) : ""}</p>
                </div>
            </div >
        )
    }
    );

    return Mapping;
}


const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{}}>
                <div className="bg-dark800 w-full h-8 rounded-full animate-pulse" />
            </div>
        )
    }
    );

    return Mapping;
}