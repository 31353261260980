import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeroImg from "../../../1.resources/3.files/images/hero13.png";
import Banner from "./banner";
import { ImArrowUpRight2 } from "react-icons/im";
import Img1 from "../../../1.resources/3.files/images/ginny.png";
import Img2 from "../../../1.resources/3.files/images/earth.png";
import Img3 from "../../../1.resources/3.files/images/john4.png";
import Img4 from "../../../1.resources/3.files/images/colors.png";
import Img5 from "../../../1.resources/3.files/images/ruby.png";
import Img6 from "../../../1.resources/3.files/images/pran.png";
import Img7 from "../../../1.resources/3.files/images/vincent.png";
import Ticker from "react-ticker";
import { useState } from "react";
import { useEffect } from "react";
import Holders from "./holders";




let colors = [
    'bg-green-700',
    'bg-blue-700',
    // 'bg-red-700',
    'bg-yellow-700',
    // 'bg-orange-700',
    'bg-lime-700',
    'bg-emerald-700',
    'bg-teal-700',
    'bg-cyan-700',
    'bg-sky-700',
    // 'bg-rose-700',
    // 'bg-amber-700',
    'bg-stone-700',

]
class Hero extends React.Component {

    componentDidMount() {
        // document.getElementById("hero").style.marginTop = (document.getElementById("header").offsetHeight) + "px";
        // document.getElementById("main-div").style.marginTop = (document.getElementById("hero").offsetHeight) + (document.getElementById("header").offsetHeight) + "px";
        document.getElementById("hero-bg").style.height = "300px";
        document.getElementById("hero-bg").style.width = window.innerWidth + "px";
        document.getElementById("hero-image").style.marginTop = "-" + "288px";
    }
    render() {

        return (

            <div className='flex flex-col justify-start items-center md:text-center pt-0 lg:pt-0 pb-0 md:pb-10' id="hero" style={{}}>

                {/* <div className='w-full flex flex-col justify-center md:items-center text-start md:text-center pb-10 px-5 lg:px-20 pt-8 bg-white' style={{ position: "relative" }}>

                    <a className="bg-main px-8 py-2 mt-4 rounded-full flex items-center gap-x-2 w-fit" href="#w3">
                        <p className='text-md pt-0 text-white' >Explore W3</p>
                        <FontAwesomeIcon icon={["fas", "fa-angle-right"]} className="text-white" />
                    </a>
                    <p className='text-7xl md:text-8xl font-bold pt-0 text-black  pb-2 mt-8' >Your Web3 Username</p>
                    <p className='text-3xl mt-6 text-gray-500'>Your username is your identity</p>
                </div> */}

                <div style={{}} className="bg-dark900  w-full h-fit" id="hero">
                    <div style={{ filter: "blur(180px)", zIndex: -10 }} id="hero-bg" className={`${random_item(colors)} mx-0`}>

                    </div>
                    <div className='block pt-6 pb-8 w-full h-full' id="hero-image" style={{}}>
                        {/* <p className='text-2xl font-bold pt-0 text-white  pb-0 mt-0'>Explore, register, and trade</p> */}
                        {/* <div className="w-full flex-1 flex justify-center z-10" id="mainpart">
                            <div className="w-full 2xl:w-[1536px] px-5 md:px-10 lg:px-20 2xl:px-0 flex justify-center"> */}
                        <p className='text-7xl md:text-7xl font-semibold pt-0 text-white text-start md:text-center pb-8 mt-0 px-5'>Discover your <br />web3 username</p>
                        {/* <p className='text-3xl mt-0 text-white text-center pb-8'>Your username is yoxur identity</p> */}
                        {/* </div>
                        </div> */}
                        {/* <img src={HeroImg} className='w-full mt-6' style={{ filter: "brightness(1.1)" }} /> */}
                        <ImageBanner />

                    </div>
                </div>
                {/* <div className="w-full flex-1 flex justify-center pt-0 md:pt-10 bg-black z-10" id="mainpart">
                    <div className="w-full 2xl:w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0 flex justify-start">
                        <p className='text-7xl md:text-7xl font-semibold pt-0 text-white text-start pb-8 mt-0 px-0' >Meet your web3 username.</p>
                    </div>
                </div> */}
                <div className="w-full flex-1 flex justify-center pt-0 md:pt-4 bg-black z-10" id="mainpart">
                    <div className="w-full 2xl:w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                        <div className='w-full bg-dark900 px-5 md:px-14 py-4 z-10' style={{ position: "relative" }} id="main-div">
                            <Banner />

                        </div>
                    </div>
                </div>

                <div className="w-full flex-1 flex justify-center pt-0 md:pt-10 bg-black z-10" id="mainpart">
                    <div className="w-full 2xl:w-[1536px] px-0 md:px-10 lg:px-20 2xl:px-0 flex justify-center">
                        <div className='w-full bg-dark900 px-5 md:px-14 py-0 md:py-4 z-10' style={{ position: "relative" }} id="main-div">
                            <Holders />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero;


// const Recent = () => {

//     return (
//         <div>

//         </div>
//     )
// }

function random_item(items) {
    return items[Math.floor(Math.random() * items.length)];
}


const ImageBanner = ({ }) => {
    const [array, setArray] = useState([])


    let carouselItems = [
        { "index": "1", "image": Img1 },
        { "index": "2", "image": Img2 },
        { "index": "6", "image": Img6 },
        { "index": "3", "image": Img3 },
        { "index": "7", "image": Img7 },
        { "index": "4", "image": Img4 },
        { "index": "5", "image": Img5 },
    ]

    useEffect(() => {
        setArray(carouselItems);
    }, [])

    return (
        <div className="py-3 flex items-center" style={{}}>
            <div className="w-full">
                {array.length != 0 ? (
                    <Ticker style={{}} speed={0.5} >
                        {({ index }) => (
                            <>
                                <div style={{ display: "flex" }}>
                                    <GetMapping array={array} />
                                </div>
                            </>
                        )}
                    </Ticker>
                ) : (<div />)}
            </div>
        </div >
    );

}


const GetMapping = ({ array }) => {
    const mapping = array.map((item, index) =>
        <div key={index} className="w-60 h-60 2xl:w-60 2xl:h-60 mx-6">
            <img src={item.image} className='w-60 h-60 2xl:w-60 2xl:h-60' style={{ borderRadius: "40px" }} />
        </div >
    );
    return mapping;
}