import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Poll from 'react-polls';
import { useAccount } from "wagmi";
import { ConnectWallet } from "../0.global/wallet/connectWallet";
import { LeafPoll, Result } from 'react-leaf-polls'
import 'react-leaf-polls/dist/index.css'
import { colors } from "../../1.resources/1.css/colors";
import { signMessage } from '@wagmi/core'
import { callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";
import CloudContracts from "../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { timeToString } from "../../1.resources/2.js/0.global/0.smallfunctions/time";
import Img from "../../1.resources/3.files/images/2digits.png";

const Vote = ({ walletConnected, setWalletConnected }) => {
    const [pollAnswers, setPollAnswers] = useState([
        { id: 0, text: 'Support', votes: 2 },
        { id: 1, text: 'Oppose', votes: 3 },
    ])
    const [pollQuestion, setPollQuestion] = useState('Choose an option to vote');
    const [pollVisible, setPollVisible] = useState(true);
    const [voted, setVoted] = useState(null)
    let { address } = useAccount();
    let endTimestamp = 1683053631000;

    useEffect(() => {
        document.getElementById("title").innerHTML = "Poll - W3 Name Service"
    }, [])

    async function getVotes() {
        let owned = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsErc721Contract.balanceOf(address);
        console.log(owned.toNumber());

        if (owned.toNumber() == 0) {
            setPollQuestion('You need to own at least 1 WNS domain to vote');
            setPollVisible(false);
        } else {
            let [result, hasVoted] = await Promise.all([callW3Api("/database/polls/getVotes"), callW3Api("/database/polls/hasVoted", { address: address })]);
            console.log(result, hasVoted);

            setPollAnswers([
                { id: 0, text: 'Support', votes: result.support },
                { id: 1, text: 'Oppose', votes: result.oppose },
            ])

            if (hasVoted) {
                setPollQuestion('Thank you for voting!');
                setVoted(true);
            } else {
                setVoted(false);
            }
        }

    }

    useEffect(() => {
        getVotes()
    }, [])

    async function handleVote(voteAnswer) {
        setPollVisible(false);
        console.log("Voted", voteAnswer)

        setPollQuestion('Sign the message in your wallet to vote');

        let message = `WNS Community Poll\n\nObjective: Introducing Flexible Domains (renewal + non-renewal)\n\nAddress: ${address}\n\nVote: ${voteAnswer.text}`;
        const orderSignature = await signMessage({ message: message })
        console.log(orderSignature);

        let result = await callW3Api("/database/polls/vote", { address: address, vote: voteAnswer.text, sig: orderSignature });

        if (result == "ok") {
            setPollQuestion('Thank you for voting!');
            setVoted(true);
            setPollVisible(true);
        }
    }

    const customTheme = {
        textColor: 'black',
        mainColor: 'rgb(147 197 253)',
        backgroundColor: 'rgb(255,255,255)',
        alignment: 'center',
    }
    return (
        <div className="min-h-screen bg-white flex justify-center">
            <div className="bg-white w-[1024px] lg:my-0 px-5 lg:px-20 py-10 rounded-2xl">
                <img src={Img} className="w-full" />
                <div>
                    <div className="bg-green-100 w-fit px-4 py-2 rounded-md">
                        <p className="text-green-500 text-sm">PRESALE</p>
                    </div>
                    <p className="font-bold text-4xl my-4">WNS - Two-Character Domains Presale</p>

                    {/* <div className="md:flex items-center justify-between mt-3">
                        <div className="flex items-center gap-x-2">
                            <p>by</p>
                            <div className="flex items-center gap-x-2">
                                <div className="bg-blue-500 w-6 h-6 flex justify-center items-center" style={{ borderRadius: "8px" }}>
                                    <FontAwesomeIcon icon="user-alt" className="text-xs text-white" />
                                </div>
                                <a className="text-main">patrick.web3</a>
                            </div>
                        </div>

                        {Date.now() < endTimestamp ? (
                            <div className="flex items-center gap-x-2 bg-gray-100 rounded-full px-4 py-2 mt-4 md:mt-0 w-fit border border-gray-200">
                                <FontAwesomeIcon icon="fa-solid fa-clock" className="text-xs text-gray-500" />
                                <p className="text-sm">Voting ends <span className="text-blue-500">{timeToString(endTimestamp)}</span></p>
                            </div>
                        ) : (
                            <div className="flex items-center gap-x-2 bg-gray-100 rounded-full px-4 py-2 mt-4 md:mt-0 w-fit border border-gray-200">
                                <FontAwesomeIcon icon="fa-solid fa-check" className="text-sm text-blue-500" />
                                <p className="text-sm text-blue-500">Poll completed</p>
                            </div>
                        )}
                    </div> */}
                    <div className="mt-8 pt-8 border-t-2 border-gray-200">
                        <p className="text-lg">WNS is thrilled to announce the introduction of our brand-new, two-character domains. As we stand on the precipice of this exciting chapter, we're offering you the chance to participate in an exclusive presale. This is your opportunity to be a part of a groundbreaking event in digital space.
                            <br /><br />
                            During this presale, you're invited to contribute a minimum of 0.05 ETH, with no maximum limit. The more you invest, the more claim you have on these exclusive two-character domains. Your investment in the presale will determine your allocation in comparison to others, providing an equal and fair opportunity for everyone involved.
                            <br /><br />
                            Once the presale ends, you'll be able to claim your domains based on your contribution during the presale. This event is a unique opportunity to stake your claim in the evolving landscape of the digital domain.
                            <br /><br />
                            Ready to embark on this exciting journey with us? Click on the "Participate in Presale" button and join us in pioneering a new frontier in the world of digital domains.
                        </p>
                    </div>


                    <div className="bg-gray-100 rounded-xl border-2 border-gray-200 mt-8 px-8">
                        {address == null ? (
                            <div className="py-10">
                                <p className="font-bold  text-lg mb-6">Connect your wallet to participate in the poll</p>
                                <ConnectWallet />
                            </div>
                        ) : (
                            <div className="mt-6">
                                <p className="font-bold text-lg mb-4">{pollQuestion}</p>

                                {voted != null ? (
                                    <div style={{ display: pollVisible ? "block" : "none" }}>
                                        <LeafPoll
                                            type='multiple'
                                            // question='What you wanna ask?'
                                            results={pollAnswers}
                                            theme={customTheme}
                                            onVote={handleVote}
                                            isVoted={voted}
                                        />
                                    </div>
                                ) : (<div></div>)}

                                {/* <Poll answers={pollAnswers} onVote={handleVote} customStyles={{ align: "left", theme: "blue", questionSeparator: false }} /> */}
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );

}

export default Vote;
