import React, { useEffect, useState, useContext } from "react";
import { callW3Api, getWnsDomain } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { timeToString } from "../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { shortenaddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/global";
import makeBlockie from "ethereum-blockies-base64";
import { zeroAddress } from "../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import { calculateZeroes, formatinusd, usdToEth } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
const punycode = require('punycode/');

const Banner = ({ }) => {
    const [array, setArray] = useState([])

    async function getRecent() {
        let recent = await getNFTS();
        setArray(recent);
    }

    useEffect(() => {
        getRecent();

    }, [])

    /* useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [array]); */

    return (
        <div className="flex justify-center items-center w-full">
            <div className="mt-0 w-full py-6 pt-10">
                <div className="pb-4 border-b-0 border-b-light200">
                    <p className="font-semibold text-4xl text-left text-white">Recent Registrations</p>
                    <p className="text-dark500 text-left mt-2">Latest domains registered on the WNS network</p>
                </div>
                <div className="w-full overflow-x-scroll">
                    <div className="w-[600px] md:w-full">
                        {array.length == 0 ? (
                            <div className="py-2 flex items-center gap-x-2">
                                <div className="w-full lg:w-6/12">
                                    <SkeletonMapping array={Array.from(Array(5).keys())} skip={1} />
                                </div>
                                <div className="hidden lg:block w-6/12">
                                    <SkeletonMapping array={Array.from(Array(5).keys())} skip={6} />
                                </div>
                                {/* <GetSkeletons /> */}
                            </div >

                        ) : (
                            <div className="py-0 flex items-center gap-x-2">
                                <div className="w-full lg:w-6/12">
                                    <GetMapping array={array.slice(0, 5)} skip={1} />
                                </div>
                                <div className="hidden lg:block w-6/12">
                                    <GetMapping array={array.slice(5, 10)} skip={6} />
                                </div>
                            </div >
                        )
                        }
                    </div>
                </div>
            </div >
        </div>
    );

}

export default Banner;


const GetMapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <a key={index} className="flex items-center gap-x-4 my-2 py-4 hover:bg-dark800 rounded-2xl px-6" href={"/name/" + item?.name?.substring(0, item?.name?.indexOf("."))}>
            <div className="w-[30px]">
                <p className="text-left text-xl font-bold text-dark500">{skip + index}</p>
            </div>
            <div className="w-full flex items-center gap-x-4 ">
                <div className="w-20 h-20 rounded-3xl flex-none">
                    <img src={process.env.REACT_APP_API_URL + "/metadata/generateimage=" + item?.name + "?200"} className="rounded-xl" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="">
                        <p className="text-white text-lg font-semibold flex truncate">{punycode.toUnicode(item?.name ? item?.name : "")}</p>
                        <div className="flex items-center gap-x-2 mt-2">
                            <p className="text-dark500 text-sm">Owner:</p>

                            <GetDomain address={item.owner} />
                            {/* <div className="flex gap-x-2">
                                <img src={makeBlockie(item.owner_of ? item.owner_of : zeroAddress)} className="w-6 h-6 rounded-full" />
                                <p className="text-blue-500 font-semibold">{shortenaddress(item.owner_of)}</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex items-end">
                        <Cost item={item} />
                        {/* <p className="text-left text-lg text-gray-500">{item?.updated_at ? timeToString(parseInt(item?.updated_at) * 1000) : ""}</p> */}
                        {/* <MintedDate item={item} /> */}
                        <div>
                            {/* <FontAwesomeIcon icon="chevron-right" className="text-gray-500" /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <p>
                {item.normalized_metadata.name}
            </p> */}

        </a>
    );
    return mapping;
}

const GetDomain = ({ address }) => {
    const [value, setValue] = useState("");
    const [isDomain, setIsDomain] = useState(false);

    async function parseDomain() {
        try {
            let domain = await getWnsDomain(address);
            if (domain != "null") {
                setValue(domain);
                setIsDomain(true);
            }
        } catch (e) { }
    }

    useEffect(() => {
        setValue(shortenaddress(address));
        if (address && address != zeroAddress) {
            parseDomain()
        }
    }, [address])

    return (
        <div>
            {isDomain ? (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <p className="text-blue-500 font-semibold" >{value}</p>
                </div>
            ) : (
                <div className="flex gap-x-2 items-center">
                    <img src={makeBlockie(value ? value : zeroAddress)} className="w-6 h-6 rounded-full" />
                    <p className="text-blue-500 font-semibold" >{value}</p>
                </div>
            )}
        </div>
    )
}

const Cost = ({ item }) => {
    const [cost, setCost] = useState("")
    const [usdCost, setUsdCost] = useState("")
    async function init() {
        // console.log(item.normalized_metadata.name);
        // let result = await callW3Api("/database/get/cost", { name: item.normalized_metadata.name.split(".")[0] });
        // console.log(result);
        let ethCost = await usdToEth(item.cost);
        setUsdCost(formatinusd(item.cost));
        setCost(calculateZeroes((parseFloat(ethCost)).toFixed(3)));
    }

    useEffect(() => {
        init()
    }, [item])
    return (
        <div>
            <p className="text-end text-lg text-white font-semibold">{cost == "" ? "" : cost + " ETH"}</p>
            <p className="text-end text-sm text-dark500 font-semibold">{usdCost == "" ? "" : usdCost}</p>
        </div>
    )
}

const MintedDate = ({ item }) => {
    const [date, setDate] = useState("")

    async function init() {
        let result = await (await fetch(`https://deep-index.moralis.io/api/v2/block/${item.block_number_minted}?chain=eth`, {
            headers: {
                'accept': 'application/json',
                'X-API-Key': 'qh9GURBnTigYGpYJ51Xt0GeDBd7aSAseXafbWW9SLNSiwzsM0HtK1Iat3SKYkl2j'
            }
        })).json();

        setDate(result.timestamp)
    }
    useEffect(() => {
        init()
    }, [item])

    return (
        <div>
            <p className="text-left text-lg text-gray-500">{date == "" ? "" : timeToString((new Date(date)).getTime())}</p>
        </div>
    )
}

async function getNFTS() {
    let result = await callW3Api("/database/recent/get");
    return result.domains;
}


const SkeletonMapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <div key={index} className="flex items-center gap-x-4 my-6 px-2">
            <div className="w-[30px]">
                <p className="text-left text-xl font-bold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-11/12 gap-x-4">
                <div className="w-full h-20 rounded-3xl flex-none bg-dark800 animate-pulse" />
            </div>
        </div>
    );
    return mapping;
}
