import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let links = {
    "Categories": [
        { "name": "Premium Domains", "link": "/category/premium", "icon": "fa-star" },
        { "name": "Dictionary", "link": "/category/dictionary", "icon": "fa-book" },
        { "name": "Numbers", "link": "/category/numbers", "icon": "fa-stopwatch-20" },
        { "name": "Names", "link": "/category/names", "icon": "fa-users" },
        { "name": "Countries", "link": "/category/countries", "icon": "fa-globe" },
        { "name": "Cities", "link": "/category/cities", "icon": "fa-city" }
    ],
    "Marketplace": [
        { "name": "Domains", "link": "/marketplace/domains", "icon": "" },
        { "name": "Membership", "link": "/marketplace/membership", "icon": "" }
    ],
    "Account": [
        { "name": "My Domains", "link": "/account/mydomains", "icon": "" },
        { "name": "Manage", "link": "/account/manage", "icon": "" },
        // { "name": "Wishlist", "link": "/account/wishlist", "icon": "" }
    ]
}
const HeaderLinks = ({ }) => {

    return (
        <div className="flex gap-x-6 justify-center items-center w-full">
            {/* <a href="/" className="cursor-pointer font-bold text-md">Home</a> */}

            <MenuItem name="Categories" items={links.Categories} width="300%" left="-100%" />
            <MenuItem name="Marketplace" items={links.Marketplace} width="250%" left="-70%" />
            <MenuItem name="Account" items={links.Account} width="250%" left="-70%" />
            <a href="https://docs.wnsdomains.io" className="cursor-pointer font-semibold text-md text-dark500">Docs</a>
        </div>

    );

}


export default HeaderLinks;


export const MenuItem = ({ name, items, width, left }) => {

    return (
        <div className="dropdown" >
            <a className="cursor-pointer font-semibold text-md text-dark500">{name}</a>

            <div id="dropdown-content" className="dropdown-content pt-3" style={{ zIndex: "100000", width: width, left: left }}>

                <div className="bg-dark900 border border-dark800 rounded-xl ">
                    <p className="text-dark500 px-4 py-2 text-sm font-semibold border-b-2 border-b-dark800">Explore {name}</p>
                    {items.map((item, index) => (
                        <a key={index} href={item.link} className="border-b-2 border-b-dark800 ">
                            <div className="px-4 py-3 flex items-center text-black hover:bg-dark800">
                                {item.icon != "" ?
                                    (
                                        <div style={{ width: "15%" }}>
                                            <FontAwesomeIcon icon={['fas', item.icon]} className="text-white" />
                                        </div>
                                    ) : (
                                        <div style={{ width: "0%" }}>
                                        </div>
                                    )}

                                <div style={{ width: "85%" }}>
                                    <p className="font-semibold text-md text-white">{item.name}</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}